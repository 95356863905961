.headRow{
    margin-top: 6%;
}
.searchBar{
    height: 40px;
    width: 100%;
}
.sortBar{
    width: 90%;
}
.card{
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    margin: 20px 0px 15px 0px ;
}
.location{
    text-align: left;
    color: #000000;
    opacity: 0.5;
    font: normal normal 600 16px/32px Gilroy Bold;
    text-transform: capitalize;
}
.image{
    width: 90%;
    height: 90%;
    max-height: 90px;
    object-fit: cover;
    border-radius: 10px;
}
.pagination{
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 600px){
    .searchCol{
        display: flex;
        justify-content: center;
    }
    .searchBar{
        height: 50px;
        width: 90%;
    } 
    .sortCol{
        display: flex;
        justify-content: flex-end;
    }
    .sortBar{
        margin-top: 20px;
        width: 70%;
    }
    .postedCount{
        margin-top: 20px;
        font-family: 'Gilroy Bold';
        font-weight: 'bold';
    }
    .activeCount{
        color: #ED5050;
        font-family: 'Gilroy Bold';
        font-weight: 'bold'; 
    }
    .cardCol{
        display: flex;
        justify-content: center;
    }
    .card{
        width: 90%;
    }
    .tag{
        display: none
    }
    .image{
        width: 100%;
        /* object-fit: cover; */
        border-radius: 10px;
    }
    .buttonCol{
        display: flex;
        justify-content: center;
    }
}