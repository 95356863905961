/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .mainContainer {
    padding: 0px !important;
    margin-bottom: -50px;
  }
  .parentContainer {
    width: 90% !important;
    margin: auto !important;
    display: flex;
    flex-direction: column-reverse;
  }
  .allCards {
    margin-top: 30px;
  }
  .gobackIcon {
    margin-left: 5%;
    margin-top: 10px;
  }
  .socialMediaParent {
    padding-left: 5px !important;
    padding-top: 15px;
  }
  .calender {
    margin-top: 10px;
  }
}
