.dropDownMenu {
  display: none;
}
.viewProfileSmallScreen {
  display: none !important;
}
.viewProfileBigScreen {
  display: flex;
}
.resumeHeading {
  font-size: 25px !important;
}
.resumeIcons {
  display: flex;
  width: 40%;
  justify-content: space-between;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .viewProfileBigScreen {
    display: none !important;
  }
  .viewProfileSmallScreen {
    display: block !important;
  }
  .dropDownMenu {
    display: block !important;
  }
  .resumeParent {
    width: 90%;
    margin: auto;
  }
  .headerRow {
    margin-top: 20px;
  }
  .resumeHeading {
    font-size: 18px !important;
  }
  .sortingBox {
    margin-top: 20px !important;

    margin-left: 67%;
  }
  .resumeDescription {
    text-overflow: ellipsis !important;
    white-space: nowrap;
    overflow: hidden;
  }
  .updatedResumeRow {
    margin-top: -80px;
  }
  .lastUpdateText {
    text-align: left;
    margin-left: 35%;
    margin-top: -20px !important;
  }
  .resumeIcons {
    position: relative;
    top: -10px;
    left: 33%;
    width: 30%;
    max-width: 120px;
    min-width: 100px;
    justify-content: space-between;
  }
  .resumeImage {
    border-radius: 10px !important;
    width: 110px !important;
    height: 110px !important;
  }
  .resumeHeading {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .cardParent {
    /* border: 2px solid red; */
    padding-bottom: 0px !important;
  }
}
