.home-outer-div {
  margin-bottom: -46px;
}
.welcome-div {
  background: #24262b 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 50px 0 100px;
}
.ls-1 {
  border: none;
}
.landing-icons-1 {
  font: normal normal 400 17px Gilroy Bold;
  color: #b02318;
  vertical-align: super;
  margin-right: 5px;
  margin-left: 2px;
}
.ls-outline
  > .ant-select-selector
  > .ant-select-selection-search
  > .ant-select-selection-search-input
  > .ant-input:focus {
  box-shadow: 0 0 0 1px rgb(238, 80, 80, 0) !important;
}
.ls-outline
  > .ant-select-selector
  > .ant-select-selection-search
  > .ant-select-selection-search-input
  > .ant-input {
  height: 52px !important;
}
.ls-outline
  > .ant-select-selector
  > .ant-select-selection-search
  > .ant-input-affix-wrapper {
  padding: 0 !important;
  border: none !important;
}
.welcome-title {
  text-align: center;
  letter-spacing: 0px;
  opacity: 0.93;
  font: normal normal 400 61px/69px Gilroy Bold;
  color: #ffffff;
  margin-bottom: 25px;
}
.search-outline-padding {
  outline: unset !important;
  padding: 18px 32px !important;
}
.welcome-subtext {
  opacity: 0.93;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 32px;
  color: #ffffff;
  margin-bottom: 3px;
  text-align: center;
}
.welcome-subtext-2{
  opacity: 0.93;
  letter-spacing: 1px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 40px;
  text-align: center;
}
.welcome-sub-btn{
  height: 50px;
  background: #b0362d 0% 0% no-repeat padding-box !important;
  border-radius: 6px;
  opacity: 1;
  padding: 10px 50px;
  /* width: 100%; */
  color: #ffffff !important;
  font: normal normal 600 17px/20px Gilroy Bold;
  outline: 0 !important;
  border: 0 !important;
}
.landing-icons {
  font: normal normal 400 17px Gilroy Bold;
  color: #b02318;
  vertical-align: super;
  margin-right: 20px;
  margin-left: 2px;
}
.landing-input-fields {
  height: 57px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  border-radius: 6px !important;
  opacity: 1;
  font: normal normal 400 17px Gilroy SemiBold;
  width: 100%;
  top: 0;
}
.landing-input-fields input {
  font: normal normal 400 17px Gilroy SemiBold;
}
.landing-select-icon {
  position: absolute;
  left: 10px;
  top: 17px;
  z-index: 100;
}
.landing-input-fields .ant-select-selector,
.landing-input-fields .ant-select-selector .ant-select-selection-search input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0 !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  height: 57px !important;
}
.landing-input-fields .ant-select-selection-item,
.landing-input-fields .ant-select-selection-placeholder {
  padding: 11px 25px 0 !important;
  font: normal normal 400 17px Gilroy SemiBold;
}
.landing-dropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1;
  border-radius: 7px;
  opacity: 1;
  width: inherit;
}
.landing-btn {
  background: #b02318 0% 0% no-repeat padding-box !important;
  border-radius: 6px;
  opacity: 1;
  width: 100%;
  height: 57px;
  color: #ffffff !important;
  font: normal normal 400 20px/24px Gilroy Bold;
  border: 0 !important;
  outline: 0 !important;
}
.offerings-div {
  padding: 110px 0;
  background-color: #ffffff;
}
.offerings-title {
  text-align: center;
  font: normal normal 400 41px/48px Gilroy Bold;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-bottom: 55px;
}
.offerings-card {
  background-color: #00000080;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 22px;
  opacity: 1;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  height: 320px;
  /* width: 384px; */
}
.offerings-card:hover {
  box-shadow: 0px 3px 6px #00000029;
}
.o-card-title {
  padding: 145px 0 42px;
  text-align: center;
  font: normal normal 400 32px/38px Gilroy Bold;
  color: #ffffff !important;
  text-decoration: none !important;
}
.o-card-subtext {
  padding: 0 15px 80px;
  text-align: center;
  font: normal normal 400 19px/21px Gilroy Medium;
  color: #ffffff !important;
  text-decoration: none !important;
}
.landing-link {
  text-decoration: none !important;
}
.caters-div {
  background: #f5f4f4 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 50px 0;
}
.caters-title {
  text-align: center;
  font: normal normal 400 39px/46px Gilroy Medium;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-bottom: 50px;
}
.caters-cad-outer-div {
  padding: 0 18.89%;
  display: flex;
}
.caters-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 22px;
  opacity: 1;
  width: 47%;
  margin-right: 6%;
  height: 451px;
}
.caters-card-title {
  text-align: center;
  letter-spacing: -0.36px;
  color: #24262b;
  opacity: 1;
  font: normal normal 400 26px/32px Gilroy Bold;
  margin: 52px 0 13px;
}
.caters-card-subtext {
  letter-spacing: -0.19px;
  font: normal normal 400 14px/17px Gilroy SemiBold;
  color: #24262b;
  opacity: 0.5;
  text-align: center;
  margin-bottom: 35px;
}
.caters-icons {
  color: #36b37e;
  font-size: 20px;
  margin-right: 8px;
  vertical-align: text-bottom;
}
.unlimited-div {
  margin-bottom: 22px;
}
.caters-subtext-2 {
  letter-spacing: -0.26px;
  color: #24262b;
  opacity: 1;
  font: normal normal 400 19px/23px Gilroy SemiBold;
}
.landing-signup-btn {
  height: 40px;
  background: #b02318 0% 0% no-repeat padding-box !important;
  border-radius: 6px;
  opacity: 1;
  width: 100%;
  color: #ffffff !important;
  font: normal normal 400 17px/20px Gilroy Bold;
  outline: 0 !important;
  border: 0 !important;
}
.about-us-div {
  padding: 50px 0 90px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.about-title {
  text-align: center;
  font: normal normal 400 39px/69px Gilroy Medium;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 50px;
}
.about-subtext {
  padding: 0 23% 0 19%;
  text-align: center;
  font: normal normal normal 18px/21px Gilroy;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.contact-div {
  background-color: #ffffff;
  padding: 50px 0;
}
.contact-head {
  text-align: center;
  font: normal normal 400 39px/70px Gilroy Medium;
  letter-spacing: 0px;
  color: #24262b;
  opacity: 1;
  margin-bottom: 24px;
}
.contact-subtext {
  text-align: center;
  font: normal normal normal 19px/33px Gilroy;
  letter-spacing: 0px;
  color: #24262b;
  opacity: 1;
  margin-bottom: 44px;
  padding: 0 19%;
}

.landing-search-bar-icons {
  font: normal normal 400 17px Gilroy Bold;
  color: #b02318;
  vertical-align: super;
  /* margin-right: 20px; */
  margin-left: 10px;
}

.landing-search-bar-location-icon {
  font: normal normal 400 17px Gilroy Bold;
  color: #b02318;
  vertical-align: super;
  margin-right: 20px;
  margin-left: 0px;
}
.landing-search-bar-icons {
  font: normal normal 400 17px Gilroy Bold;
  color: #b02318;
  vertical-align: super;
  /* margin-right: 20px; */
  margin-left: 10px;
}

.landing-search-bar-location-icon {
  font: normal normal 400 17px Gilroy Bold;
  color: #b02318;
  vertical-align: super;
  margin-right: 20px;
  margin-left: 0px;
}
.videoContainer {
  width: 600px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .welcome-title {
    font-size: 40px;
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
    /* border: 2px solid red; */
  }
  .videoContainer {
    width: 400px;
  }
  .welcome-subtext {
    font-size: 20px;
    font-weight: normal !important;
    width: 70%;
    margin: auto;
    line-height: 30px;
    /* border: 2px solid red;  */
    margin-bottom: 30px;
  }
  .homepage-banner {
    display: flex;
    width: 80%;
    margin: auto;
    flex-direction: column;
    /* border: 2px solid red; */
  }

  .homepage-banner-items {
    width: 113vw !important;
    margin-left: 0px;
  }
  .home-location-textbox {
    width: 113vw !important;
    margin-top: -17px;
  }

  .ant-col-3 {
    margin-top: 20px;
    padding: 8px, 12px, 8px, 12px;
    width: 150px;
    max-width: 40%;
  }

  .landing-search-bar-location-icon {
    position: absolute !important ;
    left: 10px;
    top: 0px !important;
    z-index: 100;
  }

  .offerings-div-box {
    display: flex;
    flex-direction: column;
  }

  .offerings-div-box div {
    width: 115vw;
    margin-left: 7vw;
  }
  .offerings-title {
    margin-top: -50px;
    margin-bottom: 20px !important;
  }
  .o-card-title {
    padding-top: 120px !important;
    margin-left: -10px !important;
    text-align: center;
  }
  .o-card-subtext {
    margin-left: 0px !important;
    padding: 0 15px 80px;
    text-align: center;
  }

  .about-subtext {
    margin: auto;
    line-height: 30px !important;
    width: 85%;
    padding: 0px !important;
  }

  .contact-subtext {
    margin: auto;
    width: 85%;
    padding: 0px !important;
  }

  .contactus-input-btn {
    width: 180px;
    margin-left: -80px !important;
  }
  .contactus-input {
    width: 100vw !important;
    margin-left: -100px !important;
  }

  .caters-cad-outer-div {
    padding: 0px, 0px, 0px, 0px !important;
    /* border: 1px solid red; */
    width: 90%;
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin: auto !important;
  }

  .caters-card {
    border-radius: 15px;
    width: 60vw !important;
    margin-left: -40px;
    margin-right: 0%;
    height: 470px;
  }
  .caters-card-col1 {
    margin-left: -15%;
  }
  .caters-card-col2 {
    margin-left: 5%;
  }

  .for-companies-row1 {
    margin-left: 13%;
    /* border: 2px solid black; */
  }

  .for-companies-row2 {
    width: 200px !important;
    margin-left: 13%;
  }
  .Post-your-Resume {
    overflow: hidden;
    /* max-width: 250px; */
    width: 200px;
    /* border: 2px solid black; */
  }
  /* for company drop dow */
  .landing-dropdown {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d1d1d1;
    border-radius: 7px;
    opacity: 1;
    width: 159px !important;
    min-width: 159px !important;
    position: absolute;
    left: 150px;
    /* margin-left: 200px !important;
     */
  }
  .pac-container {
    width: 168px !important;
    /* background: red; */
  }
  .ant-select-dropdown {
    width: 168px !important;
    /* background: red; */
    min-width: 168px !important;
  }
}
