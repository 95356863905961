.stats-container {
  background-color: #fff !important;
  padding: 20px;
}
.stats-container .stats-label {
  font-weight: bold;
  font-size: 18px;
}
.stats-container .time-period-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stats-container .time-period-select {
  border: 1px solid #d1d1d1;
  border-radius: 7px;
  padding: 5px;
  outline: none;
  font-weight: bold;
}
