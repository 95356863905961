.headerImage {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.twitterlogo{
  width: 27px;
  margin-bottom: -7px;
}
.container{
  padding: 20px;
  background-color: #fff;
}
.antImage .ant-image{
width: 100% !important;
}
.container1{
  padding: 0px 20px 20px 20px;
  background-color: #fff;
  width: 100%;
}
.mobileContainer{
  display: none;
}
.companyLogo {
  width: 100%;
  min-width: 100px;
  height: 120px;
  border-radius: 10px;
  object-fit: cover;
}
.imageContainer{
  width: 300px !important;
  padding: 10px;
}
.headerSection {
  background-color: white;
  width: 85% !important;
  margin: auto;
  position: relative;
  z-index: 1;
  margin-top: -65px;
  padding: 22px 34px 0px 34px;
  border: 1px solid #f1eaea;
  border-radius: 10px;
}
.companyProfileHeading {
  color: #24262b;
  font-family: Gilroy Bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.27px;
  margin-bottom: 0px !important;
}
.categoryDescription {
  color: #24262b;
  font-family: Gilroy semiBold;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.5;
  text-transform: capitalize;
  line-height: 25px;
}
.profileRow1 {
  width: 100% !important;
}
.shortDescription {
  margin-top: 0px !important;
}
.locationSection {
  margin-top: 10px;
}
.profileSectionRow2 {
  width: 100%;
}
.publicProfileBody {
  width: 83%;
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.socialIcons {
  font-size: 30px;
  color: #ee5050;
}
/* .socialIconsParents {
  padding-left: 6%;
  margin-top: -10px;
} */
.companyJobTabPadding {
  padding: 20px 20px 20px 30px;
}
.Image {
  width: 100%;
  height: 100%;
  max-height: 90px;
  border-radius: 6px;
  object-fit: cover;
}
.serviceCards {
  background: white;
  padding: 20px;
}
.serviceCardsSponcered {
  text-align: right;
}
.newsCards {
  background: white;
  padding: 20px;
}
.createdOn {
  margin-top: 45px;
}
.newsImage {
  width: 80%;
  height: 100px;
  border-radius: 6px;
  object-fit: cover;
}
.companyEvetnsCard {
  background: white;
  padding: 20px;
}
.eventsImage {
  width: 80%;
  height: 100px;
  border-radius: 6px;
  object-fit: cover;
}
.productCards {
  background: white;
  padding: 20px;
  border-radius: 10px;
}
.productSponceredSection {
  text-align: right;
}
.sponceredTextSize {
  font: normal normal 400 14px/18px Gilroy semiBold !important;
  text-align: end;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 768px) {
.container1{
  display: none;
}
.mobileContainer{
  display: block;
  padding: 20px;
  background-color: #fff;
}
  .headerImage {
    height: 200px;
  }
  .companyLogo {
    width: 100%;
    height: 120px;
  }
  .headerSection {
    width: 90% !important;
    margin: auto;
    padding: 0px;
    margin-top: -50px;
  }
  .companyProfileHeading {
    margin-top: 15px;
    font-size: 18px;
    line-height: 24px;
  }
  .categoryDescription {
    font-size: 14px;
    line-height: 20px;
  }
  .jobType{
    margin-left: 10px;
  }
  .profileRow1 {
    margin-bottom: -30px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .locationSection {
    margin-top: 5px;
    font-size: 14px !important;
    opacity: 0.8;
  }
  .mobileNumber {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
  }
  .profileSectionRow2 {
    width: 100%;
    padding: 10px;
  }
  .publicProfileBody {
    width: 90%;
    margin: auto;
    margin-top: 20px;
  }
  .RightFeature {
    margin-top: 20px;
  }
  .tabsHeader {
    padding-left: 10px;
    padding-right: 8px;
  }
  /* .socialIconsParents {
    padding-left: 8%;
    margin-top: -10px;
  } */
  .socialIcons {
    font-size: 25px;
  }
  .companyDescription {
    font: normal normal 400 14px/16px Gilroy semiBold !important;
  }
  .companyJobTabPadding {
    padding: 15px;
  }
  .companyJobsCardTitle {
    font: normal normal 400 18px/25px Gilroy semiBold !important;
    letter-spacing: -0.36px;
  }
  .permanentMarginTop {
    margin-top: 10px !important;
    margin-left: -6% !important;
  }
  .sponceredTextSize {
    font: normal normal 400 14px/18px Gilroy semiBold !important;
  }
  .companyJobsCardText {
    font: normal normal 400 14px/18px Gilroy semiBold !important;
  }
  .serviceName {
    font: normal normal 400 18px Gilroy SemiBold !important;
  }
  .serviceCardsSponcered {
    text-align: left;
  }
  .companyServiceLocation {
    margin-top: -7px !important;
  }
  .createdOn {
    margin-top: 10px;
  }
  .newsImage {
    min-width: 70px;
    width: 90%;
    height: 80px;
  }
  .newsCardTitle {
    font: normal normal 400 18px Gilroy SemiBold !important;
  }
  .newsCardText {
    font: normal normal 600 14px/28px Gilroy SemiBold !important;
  }
  .newsCardIcons {
    margin-top: -7px !important;
    margin-right: -5px !important;
  }
  .eventsImage {
    width: 90%;
    height: 90px;
  }
  .likeRow {
    margin-top: -10px;
    margin-bottom: 7px;
  }
  .productCards {
    padding: 15px;
  }
  .productSponceredSection {
    text-align: left;
  }
  .shortDescription {
    margin-top: 0px !important;
  }
}
