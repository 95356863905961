.headRow {
  margin-top: 6%;
}
.upperRow {
  font-family: 'Gilroy Bold';
  font-size: 28px;
  font-weight: 'bold';
  font-style: 'normal';
}
.searchRow {
  display: flex;
  justify-content: flex-start;
}
.searchInput {
  height: 40px;
  width: 70%;
}
.image {
  width: 100%;
  height: 100px;
  object-fit: 'cover';
  border-radius: 10px;
}
.tagSpan {
  margin-left: 25px;
}
.tag {
  max-width: 100%;
}
.titleCol {
  display: flex;
}
.locationCol {
  overflow: hidden;
  /* display: flex; */
}
.locationSpan {
  padding-left: 10px;
  vertical-align: middle;
  font-family: 'Gilroy Bold';
  font-size: 15px;
  font-weight: 'bold';
  font-style: 'normal';
  opacity: 0.6;
}
.dateCol {
  padding-left: 10px;
  vertical-align: middle;
  font-family: 'Gilroy Bold';
  font-size: 15px;
  font-weight: 'bold';
  font-style: 'normal';
  opacity: 0.6;
}
.applicantHeading{
  font-weight: 700;
  margin: 20px;
  text-transform: capitalize;
  font-size: 24px;

}
.card {
  margin-top: 20px;
  border-radius: 15px;
}
@media screen and (max-width: 768px) {
  .searchRow {
    display: flex;
    justify-content: center;
  }
  .searchInput {
    height: 50px;
    width: 90%;
  }
  .cardCol {
    display: flex;
    justify-content: center;
  }
  .card {
    width: 90%;
  }
  .tagSpan {
    display: none;
  }
  .image {
    width: 100%;
    height: 70%;
    object-fit: 'cover';
    border-radius: 10px;
  }
}
