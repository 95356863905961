.footer-main-class {
  background-color: #24262b;
  display: flex;
  flex-direction: row;
  padding-top: 45px;
  padding-bottom: 40px;
  margin-top: 25px;
  font-family: Gilroy;
}

.footer-col-4-1 h3 {
  font-family: Gilroy;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.copyRightAndTerms {
  color: #9f99a5;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}

.logo-heading {
  font-size: xx-large;
  margin-left: 10px;
  color: white;
  /* padding-bottom: 20px !important; */
  font-weight: bold;
}
.footer-logo-div {
  margin-left: 35%;
  margin-top: 20px;
}
.marginMobile{
  margin-top: 40px !important;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 768px) {
  .footer-main-class {
    padding-top: 20px;
    padding-bottom: 40px;
    margin-top: 25px;
    font-family: Gilroy;
  }
  .marginMobile{
    margin-top: 80px !important;
  }
  .footer-logo-div {
    margin-left: 8%;
  }
  .master-footer-col1 {
    /* border: 2px solid red; */
    margin-left: 8%;
    margin-top: -10px;
  }
  .master-footer-col2 {
    margin-top: -10px;
    /* border: 2px solid rgb(82, 204, 0); */
  }
  .master-footer-col3 {
    margin-top: -50px;
    margin-left: 8%;
    /* border: 2px solid rgb(56, 0, 152); */
  }
}
