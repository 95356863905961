.image{
    width: 100%;
    position: 'unset';
}
.imageCol{
    margin: 40px 0 10px 0;
}
.topRow{
    margin: 40px;
}
.imageContainer{
    width: 140px;
    height: 140px;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px;
}
.deleteIcon{
    position: absolute;
    z-index: 100000;
    top: -17px;
    left: 104px;
}
@media screen and (max-width: 600px) {
    .imageCol{
        margin-left: 10px;
        margin-right: 10px;
    }
    .topRow{
        margin: 40px 20px 0px 20px;
    }
    .jobFields{
        margin-left: 10px;
        margin-right: 10px;
    }
    .buttonCol{
        display:flex;
        justify-content:center;
    }
    .button{
        width: 80%;
    }
}