.textDesignSmall {
  font: normal normal 400 18px/21px Gilroy semiBold !important;
  color: #39393acf !important;
}
.textDesignBig {
  font: normal normal 400 20px/23px Gilroy semiBold !important;
  color: #1f2024 !important;
}
.bgPink {
  background: #ffeded;
  padding: 10px 0px 3px 5px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 768px) {
  .mainContainer {
    width: 90%;
    margin: auto;
  }
  .daigramSection {
    margin-top: 10px;
  }
  .bgWhite {
    background-color: white;
    padding-left: 5%;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
  .displayNone {
    display: none !important;
  }
  .bgPink {
    background: #ffeded;
    padding: 15px 0px 5px 10px;
  }
}
