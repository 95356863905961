.job-card-image {
  width: 100%;
  height: 100%;
  padding: 20px 10px 20px 30px;
  text-align: center;
  border-radius: 20px;
}
.company-list-container {
  /* padding-top: 20px;
  padding-bottom: 20px; */
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
  height: 100%;
  margin-top: 15px;
}
.company-card-part2 {
  padding: 10px 20px 10px 0px;
}

.emp-on-smallscreen {
  display: none;
}
.tst111 {
  display: flex;
  align-items: center;
  justify-items: center;
}
.text-bold {
  font-weight: bolder !important;
}

.company-page-jobcard {
  font-size: 1.5rem;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .company-page-empty-col {
    display: none;
  }
  .company-list-top {
    /* border: 1px solid red; */
    margin-bottom: 50px;
  }
  .company-list-bottom-row {
    flex-direction: column-reverse;
    margin-top: 100px;
  }
  .company-list-map {
    margin: auto;
    margin-top: 60px !important;
    margin-bottom: 10px;
    /* border: 2px solid rgb(0, 0, 0); */
    width: 90%;
    min-height: 300px;
  }
  .card-div-head {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .job-top-nav-bar {
    background-color: transparent !important;
  }
  .s-search-bar {
    background: transparent !important;
  }

  .company-page-searchbar {
    /* border: 2px solid red; */
    width: 100% !important;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
  }
  .company-page-category-input {
    margin-left: 0px;
  }
  .company-page-location {
    margin-left: 0px;
  }
  .company-page-location-inputbox {
    /* background-color: red; */
    width: 100% !important;
  }

  /* for company card components */
  .company-list-container {
    /* border: 1px double rgb(92, 80, 2); */
    overflow: hidden;
    display: flex !important;
  }

  .emp-on-smallscreen {
    display: block;
  }
  .emp-on-smallscreen2 {
    display: none;
  }
  .job-card-image {
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-top: 20px;
    text-align: center;
    border-radius: 20px;
  }
  .company-page-jobcard {
    font-size: 1.2rem;
  }
  /* added lately to fixed a bug */
  .company-card-part2 {
    padding: 10px 10px 10px 0px;
  }
}
