.dropDownMenu {
  display: none;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .parentContainer {
    /* border: 2px solid red; */
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    padding: 10px !important;
    width: 90%;
    margin: auto;
  }
  .dropDownMenu {
    display: block;
    width: 100%;
    height: 40px;
    background: white;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .dropDownMenuContainer {
    margin-left: 4%;
  }
  .menuItems {
    color: black;
    font: normal normal 400 20px/25px Gilroy Bold;
  }
}
