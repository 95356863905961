.savedCardRow {
  width: 90%;
  margin: auto;
  display: flex !important;
  justify-content: space-between !important;
  color: #2e2d2d;
  font-size: 26px;
  font-weight: bolder;
  font-family: Gilroy-SemiBold;
}
.allCardParents {
  width: 90%;
  margin: auto;
}

.creditCardContainer {
  display: flex;
  flex-direction: row;
  height: 250px;
  width: 100% !important;
  background-color: #fff;
  border-radius: 16px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .creditCardContainer {
    height: 200px;
    border-radius: 12px;
  }

  .savedCardRow {
    width: 90%;
    font-size: 20px;
    margin-top: -50px;
    margin-bottom: -30px;
  }
  .savedCradsHeading {
    font-size: 20px;
    font-weight: bold;
    font-family: Gilroy;
  }
  .savedCradsButton {
    font-size: 15px;
    font-family: Gilroy;
  }
  .allCardParents {
    width: 90%;
  }
  .row2 {
    margin-top: -20px;
  }
  .row3 {
    margin-top: -20px;
  }
  .cardText {
    font-size: 18px !important;
  }
}
