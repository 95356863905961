@import 'antd/dist/antd.css';
@import 'bootstrap/dist/css/bootstrap.min.css';
body {
  margin: 0;
  font-family: Gilroy !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F4F6F9 !important;
  overflow: auto !important;/*modal adding bydefault overflow*/
  
}
::-webkit-scrollbar {
  width: 10px;
}
/*code {
  font-family: Gilroy !important,
    monospace;
}*/

@font-face {
  font-family: "Gilroy Bold";
  src: url("./Gilroy/Gilroy-Bold.otf");
}

@font-face {
  font-family: "Gilroy";
  src: url("./Gilroy/Gilroy-Light.otf");
}

@font-face {
  font-family: "Gilroy semiBold";
  src: url("./Gilroy/Gilroy-SemiBold.otf");
}

@font-face {
  font-family: "Gilroy medium";
  src: url("./Gilroy/Gilroy-medium.otf");
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
  } 
body {
  top: 0px !important; 
  background-color: #ffffff;
  }
  .goog-logo-link {
    display:none !important;
 }
 
 .goog-te-gadget {
    color: transparent !important;
 }
 
 .goog-te-gadget .goog-te-combo {
    color: #24262b !important;
 }
 .sortField{
  display: flex;
  align-items: center;
  /* border-radius: 6px; */
  justify-content: center;
  text-align: center;
 }
 .sortField .ant-select-selector{
  height: 40px !important;
  width: 100%;
  text-align: center;
  display: flex;
  /* align-items: center; */
  border-radius: 6px;
  justify-content: center;
}
.add_skill .ant-modal-content{
  border-radius: 10px;
  font-family: Gilroy medium ;
}
.inputLabel .ant-form-item-required{
  color: #24262b7a;
  font: normal normal 400 16px/19px Gilroy semiBold;
  margin-bottom: 15px;
  margin-top: 15px;
}
.headCollapse {
  width: 90%;
  color: red;
  border: none;
  border-radius: 0 0 10px 10px ;
  border: none !important;
  background-color: #f3f6f9 !important;
}
.headCollapse .ant-collapse{
  border-radius: 12px !important;
}
.headCollapse .ant-collapse-content-box{
  padding: 0 !important;
}
.headCollapse .ant-collapse-content{
  border-top: none !important;
}
.headCollapse .ant-collapse-header-text{
  text-align: end;
  color: red;
  font: normal normal 400 12px/19px Gilroy semiBold;
}
.headCollapse .ant-collapse-expand-icon{
  color: red;
}
.mediaUpload  .ant-upload-list-picture-card{
  padding: 5px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  height: auto !important;
}
.headCollapse .ant-collapse-item{
  border-bottom: none !important;
}
