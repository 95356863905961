.media-photo-name-tag{
    margin: 0;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.form-control-file {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 6em;
    outline: none;
    visibility: hidden;
    cursor: pointer;
    background-color: #c61c23;
    box-shadow: 0 0 5px solid 
}
.marginMobile1{
    width: 100%;
    margin-top: -10px;
}
.media-photo-name-tag-div{
	padding: 10px 60px 10px 0px;
}
.application_status > .ant-modal-content .ant-modal-footer{
    text-align: left;
}
.application_status > .ant-modal-content .ant-modal-footer > .ant-btn{
    background: #24262B;
    border-radius: 6px;
    text-transform: uppercase;
    font: normal normal bold 18px/22px Gilroy Bold;
}
.company_modal_meadi-1{
	font: normal normal bold 24px/40px Gilroy Bold;
	letter-spacing: 0.96px;
	color: #24262B;
}
.media-photo-name-tag-1{
    font: normal normal 600 18px/22px Gilroy semiBold;
    border-radius: 100px;
    text-align: center;
    color: #BF0000;
    padding: 10px 14px 10px 20px;
    background: #b1060633;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.application-modal-status-1{
    font: normal normal bold 13px/15px Gilroy Bold;
    letter-spacing: -0.18px;
    color: #EF2C44;
    text-transform: uppercase;

}
.application-modal-status-2{
    font: normal normal bold 16px/24px Gilroy Bold;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 9px 30px;
    background: #EE5050;
    border-radius: 6px;
    position: absolute;
    bottom: 40px;
    right: 20px;
    border: none;
}
@media screen and (max-width: 768px) {
    .marginMobile1{
        width: 100%;
        margin-top: 10px;
    }
}