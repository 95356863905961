.headingCol{
    display: flex;
    justify-content: center;
    background-color: #F4F6F9;
}
.heading{
    font-size: 36px;
    margin-top: 10px;
    letter-spacing: -0.49px;
    color: #24262b;
    opacity: 1;
    font-weight: bolder;
    display: flex;
    justify-content: center;
}
.sliderCol{
    display: block;
    background-color: #F4F6F9;
}
.titleCol{
    margin-top: 50px;
    display: flex;
    padding: 10px;
    width: 90%;
    background-color: #ed5050;
    border-radius: 20px;
}
.textValue{
    text-align: left;
    font: normal normal medium 28px/33px Gilroy;
    letter-spacing: -0.39px;
    color: white;
    font-size: 28px;
    font-weight: 600;
    opacity: 1;
    padding: 20px;
}
.image{
    background-color: #fff;
    margin-top: 40px;
    width: 90%;
    height: 500px;
    margin-bottom: 0px;
    object-fit: cove;
}
.image3{
    background-color: #fff;
    margin-top: 40px;
    width: 90%;
    height: 500px;
    margin-bottom: 40px;
}
.image4{
    background-color: #fff;
    margin-top: 40px;
    width: 90%;
    height: 500px;
    margin-bottom: 40px;
    border-radius: 20px;
}
.image2{
    display: none;
}
.container{
    display: flex;
    justify-content: center;
    background-color: #F4F6F9;
}
.container1{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    background-color: #F4F6F9;
}
.container4{
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    background-color: #F4F6F9;
}
.container2{
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding-bottom: 20px;
    border-radius: 20px;
}
.featureLady {
    height: 559px;
    width: 100%;
    border-radius: 13px;
    opacity: 1;
  }

.innerContainer{
    width: 90%;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
}
.innerContainer2{
    width: 90%;
    background-color: #ffeded;
    padding: 20px;
    border-radius: 20px ;
}
.innerContainer3{
    margin-top: 40px;
    width: 90%;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px ;
}
.title{
    padding: 10px;
    padding-bottom: 0px;
    text-align: left;
    font: normal normal medium 32px/38px Gilroy;
    color: #24262b;
    opacity: 1;
    font-size: 28px;
    font-weight: bold;
    opacity: 0.8;
}
.titleValue{
    padding: 10px;
    padding-bottom: 0px;
    text-align: left;
    font: normal normal normal 20px/30px Gilroy !important;
    letter-spacing: 0.18px;
    color: #24262b;
    opacity: 0.8;
    font-size: 20px !important;
    line-height: 30px;
}
.displayCenter{
    display: flex;
    justify-content: center;
}
.marginLeft{
    margin-left: 20px;
}
.paddingAll{
    padding: 20px;
}
.sliderMainCol{
    margin-left: 30px; 
    width: 95%;
    display: block; 
    justify-content: center;
}
.sliderMain{
  align-self: center;
  padding: 40px;
  width: 100%;
}
.sliderMainRow{
    height: 290px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 13px;
    opacity: 1;
    padding: 50px;
    align-self: center;
}
.image1{
    width: 80%;
    height: 80%;
}
.buttonStyle {
    background-color: #24262b !important;
    border: 0px !important;
    height: 66px !important;
    width: 358px !important;
    border-radius: 6px !important;
    opacity: 1 !important;
    font: normal normal bold 29px/44px Gilroy !important;
    letter-spacing: 0px !important;
  }
  .aboutusContainer{
    display: flex;
    justify-content: center;
  }
  .aboutusInnerContainer{
    justify-content: center;
    background-color: #f5f4f4;
    padding: 30px;
    text-align: center;
  }
  .aboutusImage{
    width: 50%;
    height: 50%;
  }
  .aboutusTitle{
    padding: 10px;
    padding-bottom: 10px;
    text-align: center;
    font: normal normal bold 29px/44px Gilroy;
    letter-spacing: 0.18px;
    color: #000;
    opacity: 0.8;
    font-size: 35px ;
    line-height: 30px;
  }
  .aboutusTitle1{
    padding: 15px;
    padding-bottom: 10px;
    text-align: center;
    font: normal normal bold 40px/44px Gilroy;
    letter-spacing: 0.18px;
    color: #000;
    opacity: 0.8;
    font-size: 25px ;
    line-height: 30px;
  }
  .aboutusRedContainer{
    background-color: #c79a9a;
    background: #c01718 0% 0% no-repeat padding-box;
    text-align: center;
    align-content: center;
    padding: 10px;
    margin: 20px;
    border-radius: 10px;
  }
  .aboutusRedTab{
  opacity: 1;
  font-size: 38px;
  letter-spacing: -0.51px;
  color: #ffffff;
  font-weight: normal;
  }
  .aboutBox{
    margin: 20px;
    width: 100%;
    height: 685px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
  }
@media screen and (max-width: 768px) {
    .sliderMainRow{
        height: 290px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 13px;
        opacity: 1;
        padding: 20px;
        align-self: center;
        text-overflow: ellipsis;
    }
    .sliderMain{
        align-self: center;
        padding: 40px;
        width: 90%;
      }
    .image{
        width: 90%;
        height: 300px;
    } 
    .image3{
        background-color: #fff;
        width: 100%;
        height: 300px;
        margin-top: 0px;
    }
    .image4{
        background-color: #fff;
        width: 100%;
        height: 300px;
        margin-top: 30px;
        border-radius: 20px;
    }
    .image1{
        margin-top: 0px;
        width: 90%;
        height: 30%;
    }
    .image2{
        background-color: #fff;
        margin-top: 20px;
        margin-bottom: 0px;
        width: 100%;
        height: 300px;
        display: block;
    }
    .sliderHeading{
        font-size: 25px;
    }
}