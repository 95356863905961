.header{
    display: flex ;
    justify-content: center ;
}
.headerCol{
    width: 90% !important;
}
.nameCol{
    overflow: hidden;
}
.dateCol{
    display: flex ;
    justify-content: flex-end ;
}
.card {
    width: 100%;
    border-radius: 15px
}
.date{
    margin-left: 0.5rem;
}
.locationCol{
    margin-top: 10px;
}
.location{
    margin: 5px 0px 0px 5px;
    text-transform: capitalize,
}
.locationImg{
    width: 15px;
    height: 15px;
}
.locationImg2{
    width: 20px;
    height: 20px;
}
.location2{
    margin-left: 10px;
    font: normal normal 400 16px Gilroy semiBold;
    opacity: 0.6;
}
.image{
    width: 90%;
    height: 90%;
    max-height: 90px;
    border-radius: 10px;
}
.title{
    color: #ED5050;
    font-weight: bold;
}
.titleValue {
    font: normal normal 400 16px Gilroy semiBold;
    text-overflow: ellipsis;
  }
  .titleMargin{
    background-color: #f4f6f9;
    border-radius: 10px;
    /* margin-top: 5px; */
    text-overflow: hidden;
  }
  .headMargin{
    margin-top: 10px;
    text-overflow: hidden;
  }
  .titleValueCompanyName{
    font: normal normal 400 15px Gilroy semiBold;
    color: #24262b;
    padding: 0px !important;
    /* margin: 5px; */
    text-transform: uppercase;
  }
  .mailbox{
    cursor: pointer;
  }
  .mailbox1{
    cursor: pointer;
    display: none;
  }
@media screen and (max-width: 600px) {
    .checkBox{
        justify-content: flex-end;
    }
    .dateCol{
        display: flex ;
        justify-content: flex-start ;
    }
    .locationCol{
        margin-top: 0px;
    }
    .image{
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    .mailbox{
        display: none;
      }
      .mailbox1{
        cursor: pointer;
        display: flex;
      }
}