/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .mainContainer {
    display: flex;
    flex-direction: column-reverse;
  }
  .filterButton {
    width: 100%;
  }
  .searchLocationBox {
    width: 100%;
  }
  .servicePageMapSection {
    width: 90%;
    margin: auto;
    margin-bottom: 300px;
  }
}
