.profileImg {
  width: 90%;
  height: 90%;
  /* object-fit: cover; */
  border-radius: 10px;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 768px) {
  .displayNone {
    display: none;
  }
  .fontTextBig {
    font: normal normal 400 20px/19px Gilroy semiBold !important;
    color: #24262b !important;
  }
  .header {
    background: white;
    padding: 0px 0px 10px 5%;
  }
  .productTab {
    width: 90%;
    margin: auto;
  }
  .profileImg {
    width: 100%;
    height: 80%;
  }
  .serviceCard {
    padding: 0px !important;
  }
}
