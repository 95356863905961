.heading{
    font-weight: 700px;
    margin-top: 20px;
    margin-left: 20px;
    text-transform: capitalize;
    font: normal normal 400 25px/14px Gilroy semiBold;
}
.headCol{
    /* margin-top: 20px; */
    width: 100%;
    background-color: #fff;
}
.imageCol{
    /* padding: 20px; */
}
.card{
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    margin: 20px 0px 15px 0px ;
}
.image{
    width: 90%;
    height: 90%;
    max-height: 90px;
    /* object-fit: cover; */
    border-radius: 10px;
}
.image1{
    width: 100%;
    height: 90%;
    border-radius: 10px;
    max-height: 90px;
    /* object-fit: contain; */
}

.mainCol{
    display: flex;
    justify-content: center;
}
.titleCollapse{
    color:#ee5050;
    text-transform: capitalize;
    font-weight: bold;
}
.titleCollapseValue{
    font-family: 'Gilroy Bold';
    font-size: 15px;
    font-weight: 'bold';
    font-style: 'normal';
    /* margin-left: 5px; */
    word-break: break-all;
}
.titleCollapseApplied{
    background-color: #ffedee;
    padding: 3px;
    border-radius: 20px;
}
.titleValue{
    font-family: 'Gilroy Bold';
    font-size: 15px;
    font-weight: 'bold';
    font-style: 'normal';
    margin-left: 5px;
}
.title{
    /* margin-top: 15px; */
    font-family: 'Gilroy Bold';
    font-size: 24px;
    font-weight: 'bold';
    font-style: 'normal';
}

.appliedCount{
    margin-left: 15px;
    background-color: #f7dcdf;
    padding: 4px 10px 4px 10px;
    border-radius: 15px;
    display: initial;
    font: normal normal 400 12px/14px Gilroy semiBold;
    color: #ee5050;
}
.sideMargin{
    margin-left: 5px;
}
.date{
    font-family: 'Gilroy Bold';
    font-size: 15px;
    font-weight: 'bold';
    font-style: 'normal';
    margin-left: 5px;
}
.resumeImg{
    width: 14;
    margin-left: -5px;
}
.resume{
    margin-left: 5px;
    vertical-align: middle;
    color: #ee5050;
    font-weight: bold;
}
.location{
    font-family: 'Gilroy Bold';
    font-size: 15px;
    font-weight: 'bold';
    font-style: 'normal';
    opacity: 0.6;
    margin-left: 5px;
}
.search{
    height: 40px;
    width: 100%;
}
.price{
    font-family: 'Gilroy Bold';
    font-size: 15px;
    font-weight: 'bold';
    font-style: 'normal';
    margin-left: 5px;
}
@media screen and (max-width: 600px) {
    .headCol{
        width: 90%;
    }
    .searchCol{
        display: flex;
        justify-content: center;
        width: 90%;
    }
    .search{
        height: 50px;
        width: 90%;
    }
    .card{
        width: 90%;
    }
    .image1{
        width: 120%;
    }
  }