.paginationContainer {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .candidateHeading {
    display: none;
  }
  .parentContainer {
    width: 90%;
    margin: auto;
  }
  .serviceCard {
    padding: 0px !important;
  }
  .locationSection {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
