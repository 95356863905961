.coverImage {
  width: 100%;
  position: unset;
  height: 300px;
  object-fit: cover;
}
.coverDefaultImage {
  width: 100%;
  position: unset;
  height: 300px;
  object-fit: contain;
}
.locationBox {
  position: relative;
  width: 100%;
  height: 300px;
}
.hideDiv {
  display: none;
}
.buttonCol{
  display: flex;
  justify-content: flex-end;
}
.button{
  color: #fff !important;
  font: 400 18px Gilroy semiBold;
  margin: 10px 5px;
  padding: 0px 40px;
  background-color: #343a40 !important;
  align-items: center;
  height: 50px;
  border-radius: 8px;
}
.inputBoxW100 {
  height: 54px !important;
}
.imageContainer{
  width: 140px;
  height: 140px;
  border: 1px solid grey;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 10px;
}
.deleteIcon{
  position: absolute;
  z-index: 100000;
  top: -17px;
  left: 102px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .headerRowForSmallScreen {
    display: none;
  }
  .coverImage {
    height: 200px;
  }
  .coverDefaultImage {
    height: 200px;
  }
  .companyProfilePic {
    margin-left: 40%;
    margin-top: -80px !important;
    border-radius: 50% !important;
  }
  .mainContainer {
    width: 90%;
    margin: auto;
    margin-bottom: -70px;
  }
  .fromPaddingRight {
    padding-right: 0px !important;
  }
  .inputBoxW100 {
    width: 100% !important;
    height: 54px !important;
  }
  .fromPaddingLeft {
    padding-left: 0px !important;
  }
  .fromPaddingRight {
    padding-right: 0px !important;
  }
  .socialTab {
    width: 90%;
    margin: auto;
    margin-bottom: -30px;
  }
  .contactInfoTab {
    width: 90%;
    margin: auto;
    margin-bottom: 40px;
  }
  .mediaTabs {
    width: 90%;
    margin: auto;
    margin-bottom: -50px;
  }
  .mediaTabsBtnOld {
    display: none !important;
  }
  .mediaTabsBtn {
    width: 100%;
  }
  .hideDiv {
    display: block;
    background: whitesmoke;
    height: 100px;
    position: relative;
    z-index: 10;
    top: 12vh;
  }
  .userTabsContainer {
    width: 90%;
    margin: auto;
  }
  .userTabHeaderRow {
    width: 90%;
    margin: auto;
  }
  .changePassword {
    display: flex;
    justify-content: center;
  }
}
