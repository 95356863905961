.nodataMainContainer {
  height: 100vh;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .nodataMainContainer {
    height: 100%;
  }
}
