.headRow{
    margin-top: 6%;
    display: flex;
}
.headCol1{
    font-family: 'Gilroy Bold';
    font-size: 28px;
    font-weight: 'bold';
    font-style: 'normal';
}
.headCol2{
    display: flex;
    justify-content: flex-end;
}
.searchRow{
justify-content: space-between;
}
.dateInnerRow{
    padding-top: 15px;
}
.imageCol{
    display: flex;
}
.titleCol{
    display: flex;
    justify-content: space-between;
}
.cover{
    margin: 40px 0px 10px 0px;
}
.imageContainer{
    width: 140px;
    height: 140px;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px;
}
.card {
    margin-top: 20px;
    border-radius: 15px;
}
.deleteIcon{
    position: absolute;
    z-index: 100000;
    top: -17px;
    left: 108px;
}
@media screen and (max-width: 600px) {
    .card {
        margin-left: 30px;
        width: 90%;
        display: flex;
        justify-content: center;
    }
    .headCol2 {
        display: none !important;
    }
    .jobElement{
        font-family: 'Gilroy Bold';
        font-size: 16px;
        font-weight: 'bold';
        font-style: 'normal';
        margin-top: 20px;
    }
    .activeElement{
        color: #ee5050 !important;
        font-family: 'Gilroy Bold';
        font-size: 16px;
        font-weight: 'bold';
        font-style: 'normal';
        margin-top: 7px;
    }
    .searchRow{
        width: 90%;
        margin: auto;
    }
    .sortRowInner{
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        width: '90%';
    }
    .appliedTag{
        display: none;
    }
    .buttonCol{
        display: flex;
        justify-content: center;
    }
    .searchInput{
        height: 50;
        width: 100%;
    }
    .goBack{
        font-size: 24px;
        font-weight: bold;
    }
    .cover{
        margin: 40px 20px 10px 10px;
    }
    .jobFieldCol{
        margin-left:10px;
    }
    .jobTypeCol{
        margin-left:10px;
        width: 90%;
    }
    .labelField{
        font-size: 20px;
        font-family: 'Gilroy Bold';
        font-weight: bold;
    }
    .buttonColBottom{
        display: flex;
        justify-content: center;
    }
  }