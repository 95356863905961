.main-header-logo {
  font: normal normal bold 26px/32px Gilroy Bold;
  text-align: left;
  letter-spacing: 0px;
  color: #333333;
  display: inline-block;
  padding-top: 20px;
}
.navbar-custom {
  height: 80px;
  padding: 0 7.7% 0 8.33% !important;
  background: #ffffff;
}
.navbar-container{
display: flex;
justify-content: flex-start;
}
.image {
  border-radius: 50%;
  width: 10% !important;
  height: 4% !important;
  margin-top: 3px;
  margin-left: 15px;
}
.navcomp {
  margin-left: auto !important;
  margin-right: 0 !important;
  text-transform: uppercase;
}
.navbar-nav {
  flex-direction: inherit !important;
}

.navbar-nav li {
  padding-right: 15px;
  padding-left: 15px;
}
.navbar-nav {
  font: normal normal 600 14px/17px Gilroy Bold;
}
.top-nav-icons {
  font: normal normal 400 16px Gilroy Bold;
  color: #ee5050 !important;
  vertical-align: -webkit-baseline-middle !important;
  cursor: pointer;
  position: relative;
}
.dropdown-text {
  color: #333333;
  font: normal normal 400 14px Gilroy Bold;
}
.navbar-expand-lg > a {
  color: #333333;
  text-decoration: none;
}
.unseen-count-no {
  position: relative;
  left: 1.2vw;
  top: -20px;
  color: #ee5050;
  font-size: smaller;
}
.hamburger-icons {
  display: none;
}
.navbar-display-none-on-bigscreen {
  display: none;
}
/*********************** for mobile size screen *************************/
@media screen and (max-width: 650px) {
  .navbar-display-none {
    display: none;
  }
  .navbar-display-none-on-bigscreen {
    display: block;
  }
  .navlink-smallscreen {
    display: block;
    color: black;
    text-align: left;
    /* border: 2px solid red; */
    font: normal normal 400 18px/32px Gilroy SemiBold;
    margin-left: -15px;
    padding-bottom: 10px;
  }
  .navlink-smallscreen-profile{
    margin-left: 30px;
    font-weight: 900;
     margin-top: 15px;
     font: normal normal 400 18px/32px Gilroy SemiBold;
  }
  .red-color-smallscreen {
    color: rgb(180, 5, 5);
  }
}
