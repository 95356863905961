.cutom-map-for-header
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > .gm-style-iw-a
  > .gm-style-iw-t
  > div {
  padding: 0 !important;
  max-height: unset !important;
}
/*.cutom-map-for-header > div > div > div > div > div > div > div > div > .gm-style-iw-a > .gm-style-iw-t > div > button{
	display: none !important;
}*/
.cutom-map-for-header
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > .gm-style-iw-a
  > .gm-style-iw-t
  > div
  > .gm-style-iw-d {
  overflow: unset !important;
  max-height: unset !important;
}
.cutom-map-for-header
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > .gm-style-iw-a
  > .gm-style-iw-t
  > div
  > .gm-style-iw-d
  > div
  > div
  > .ant-card-body {
  padding: 0 !important;
}
.custom-map-info-window-text {
  padding: 5px 13px;
  /* overflow-x: "scroll"; */
  /* height: 300px; */
}

.marker-scrol {
  height: 10vh;
  width: 15vh;
  overflow-x: scroll;
}

.marker-scrol::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #aca8a8;
}

.custom-map-info-window-title {
  font: normal normal bold 16px/19px Gilroy SemiBold;
  color: #24262b;
  letter-spacing: -0.22px;
  padding-bottom: 5px;
}
.custom-map-info-window-company-name {
  font: normal normal bold 12px/14px Gilroy SemiBold;
  color: #24262b;
  opacity: 0.6;
  letter-spacing: -0.17px;
  padding-bottom: 8px;
}
.custom-map-info-window-location {
  font: normal normal bold 13px/15px Gilroy SemiBold;
  color: #24262b;
  opacity: 0.6;
  letter-spacing: -0.17px;
  padding-top: 5px;
  padding-bottom: 8px;
}
.custom-map-info-window-salary {
  font: normal normal bold 14px/17px Gilroy SemiBold;
  letter-spacing: -0.19px;
  color: #000000;
  padding-bottom: 5px;
}
.custom-map-info-window-job-type span {
  background: #ee50501f;
  border-radius: 2px;
  padding: 2px 5px;
}
.custom-map-info-window-job-type {
  font: normal normal bold 12px/14px Gilroy SemiBold;
  letter-spacing: 0px;
  color: #ee5050;
  padding-bottom: 8px;
}
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.card {
  display: inline-block;
}
.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
}

.cutom-map-for-header {
  width: 100%;
  height: 100%;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .cutom-map-for-header {
    height: 300px !important;
  }
}
