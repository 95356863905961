.headerSection {
  width: 100%;
}
.bannerImge {
  width: 100%;
  position: unset;
  height: 300px;
}
.editImage {
  cursor: pointer;
  overflow: hidden;
}
.dipNoneOnBig {
  display: none;
}
.uploadImageSection {
  background-color: #eeeeee;
  border-radius: 0px;
  border: 1px dashed #999999;
  height: 8vw;
  width: 8vw;
}
.uploadImage {
  cursor: pointer;
  margin-top: 5vw;
}
.previewImg {
  margin-top: 50px;
  margin-left: -250px;
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 15px;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .headerSection {
    padding-top: 0px !important;
    margin-bottom: -70px;
    background: white;
    padding-left: 5%;
    padding-right: 5%;
  }
  .profileHeading {
    margin-top: 15px;
  }
  .bannerImge {
    height: 200px;
    margin-top: 30px;
    margin-bottom: -25px;
    border-radius: 0px !important;
  }
  .editImage {
    margin-top: 70px !important;
  }
  .viewPublicProfile {
    z-index: 10;
    margin-top: 20px;
  }

  .viewPublicProfileChild {
    margin-top: -30px;
  }
  .profileHeadingText {
    font-size: 21px;
  }
  .salaryTabsParent {
    width: 90%;
    margin: auto;
  }
  .enterPriceBox {
    width: 100vw !important;
  }
  .maxSalary {
    width: 100vw !important;
  }
  .formPaddingLeft {
    margin-left: -12px !important;
  }
  .dipNoneOnBig {
    display: block;
    margin-bottom: 20px;
  }
  .dipNoneOnSmall {
    display: none;
  }
  .formPaddingRight {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .formPaddingLeft1 {
    padding-left: 0px !important;
    padding-right: 5% !important;
  }
  .formPaddingLeftContact {
    width: 100% !important;
    padding-right: 0px !important;
  }
  .formPLContact {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .mapSection {
    margin-bottom: 60px;
  }
  .w100 {
    width: 100% !important;
  }
  .qualificationTab {
    width: 90%;
    margin: auto;
  }
  .calenderFirstBox {
    padding-right: 0px !important;
  }
  .calenderSecondBox {
    padding-left: 0px !important;
  }
  .universityBox {
    padding-right: 0px !important;
  }
  .universityBoxChild {
    padding-right: 0px !important;
    width: 100% !important;
  }
  .firstQfcBox {
    padding-right: 0px !important;
  }
  .secondQfcBox {
    padding-left: 0px;
    width: 100% !important;
  }
  .uploadImageSection {
    background-color: #eeeeee;
    border-radius: 0px;
    border: 1px dashed #999999;
    height: 15vh;
    width: 30vw;
  }
  .uploadImage {
    width: 100%;
    cursor: pointer;
    margin-top: 30px !important;
  }
  .previewImg {
    margin-top: 50px;
    margin-left: -50px;
    width: 125px;
    height: 125px;
    object-fit: cover;
  }
  .expTab {
    width: 90%;
    margin: auto;
  }
  .exptabPaddingRight {
    padding-right: 0px !important;
  }
  .exptabPaddingLeft {
    padding-left: 0px !important;
  }
  .currentlyWorking {
    padding-left: 0px !important;
    padding-top: 20px !important;
    text-align: left !important;
  }
  .expTabW100 {
    width: 100% !important;
  }
}
