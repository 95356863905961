.mail-box-container .mail-box-label {
  font: normal normal 400 28px/32px Gilroy Bold;
}
.mail-box-container .new-chat-btn {
  letter-spacing: -0.22px;
  color: #ee5050;
  font: normal normal bold 16px/19px Gilroy;
  cursor: pointer;
}
.mail-box-container .plus-btn-container {
  cursor: pointer;
}
.mail-box-container .sort-n-newchat-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.mail-box-container .sort-n-newchat-container .sort-container {
  width: 150px;
}
.unseen-msg-card {
  cursor: pointer;
  border: 2px solid #ee5050;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: auto;
  padding: 10px;
}
.unseen-msg-card .displayed-msg-container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.unseen-msg-card .displayed-msg-container .displayed-msg {
  font: normal normal 600 14px/17px Gilroy;
  font-size: 14px;
}
.unseen-msg-card .displayed-msg-container .unseen-count {
  background-color: #ee5050;
  color: #fff;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  padding-top: 5px;
}
.user-card-container {
  cursor: pointer;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: auto;
  padding: 10px;
}
.user-card-container .displayed-msg-container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.user-card-container .displayed-msg-container .displayed-msg {
  font: normal normal 600 14px/17px Gilroy;
  font-size: 14px;
}
.user-card-container .displayed-msg-container .unseen-count {
  background-color: #ee5050;
  color: #fff;
  width: 30px;
  text-align: center;
  border-radius: 50%;
}
.pic-name-container {
  display: flex;
  flex-direction: row;
}
.pic-name-container .user-name {
  color: #24262b;
  font: normal normal 400 28px/32px Gilroy Bold;
  font-size: 18px;
}
.pic-name-container .user-pic-container .user-pic {
  height: 46px;
  width: 46px;
  border-radius: 50% !important;
}
.chat-box-container {
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 10px;
}
.chat-box-container .time-stamp {
  font-size: 12px;
  color: #24262b;
  opacity: 0.7;
  margin: 2px;
}
.chat-box-container .chat-box-header {
  display: flex;
  flex-direction: row;
}
.chat-box-container ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  overflow-x: hidden;
}
.chat-box-container ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ee5050;
}
.chat-box-container .msg-container {
  margin-top: 20px;
  background: #fff 0% 0% no-repeat padding-box;
  height: 480px !important;
  overflow-y: scroll;
}
.chat-box-container .msg-container .msg-style-user {
  height: auto;
  background-color: #f4f6f9;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 0px 10px 10px 10px;
  margin-right: 40px !important;
  margin-bottom: 20px !important;
}
.chat-box-container .msg-container .msg-style-self {
  height: auto;
  background-color: #f4f6f9;
  padding: 10px;
  display: flex;
  align-items: center;
  /* text-align: right; */
  border-radius: 10px 0px 10px 10px;
  margin-left: 40px !important;
}
.chat-box-container .user-pic-container .user-pic {
  height: 46px;
  width: 46px;
  border-radius: 50% !important;
}
.msg-creater-container {
  border-top: 0.5px solid #dee2e6;
  padding: 20px;
  display: flex;
  flex-direction: row;
  outline: none;
}
.msg-creater-container .option-btn {
  width: 10px;
  height: 10px;
}
.msg-creater-container .msg-creater-area {
  width: 100%;
  border: 0px;
  outline: none;
}
.msg-creater-container .attach-btn {
  height: 30 px;
  width: 30px;
}
.msg-creater-container .send-btn-container {
  border: 0px !important;
  background-color: transparent;
}
.msg-creater-container .send-btn-container .send-btn {
  height: 30px;
  width: 30px;
}
.msg-creater-container .ant-upload-list-item {
  height: 86px !important;
  padding: 8px !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
  width: 295px;
  background-color: #fff !important;
  margin: auto;
}
.user-pic {
  height: 46px;
  border-radius: 50% !important;
}
.user-name {
  color: #24262b;
  font: normal normal 400 28px/32px Gilroy Bold;
  font-size: 18px;
}
.time-style {
  color: #24262b;
  opacity: 0.4;
}
.uploaded-image-container {
  display: none;
}
