.detail-outer-div {
  padding: 25px 7.64% 0 8.33%;
}
.service-d-img {
  border-radius: 5px;
}
.s-detail-name {
  margin-top: 20px;
  font: normal normal 400 28px Gilroy SemiBold;
  color: #24262b;
  text-transform: capitalize;
}
.s-detail-comp-name {
  color: #24262b;
  opacity: 0.5;
  font: normal normal 400 18px Gilroy SemiBold;
  text-transform: uppercase;
}
.s-detail-short-desc {
  letter-spacing: 0.18px;
  color: #000000;
  opacity: 0.5;
  font: normal normal 400 13px Gilroy SemiBold;
  margin-top: 12px;
}
.s-detail-attr {
  letter-spacing: -0.22px;
  color: #000000;
  opacity: 0.4;
  font: normal normal 400 16px Gilroy SemiBold;
  margin: 25px 0 5px;
}
.s-detail-val {
  letter-spacing: -0.22px;
  color: #000000;
  opacity: 1;
  font: normal normal 400 16px Gilroy SemiBold;
  text-transform: capitalize;
}
.send-msg-btn {
  background-color: #24262b !important;
  width: 170px;
  height: 50px;
  font: normal normal 400 16px Gilroy SemiBold;
  color: #ffffff !important;
  border-radius: 6px;
  outline: 0 !important;
  border: 0 !important;
}
.bookmark-div {
  border: 2px solid #1f2125;
  border-radius: 6px;
  opacity: 1;
  display: inline-block;
  height: 50px;
  margin-left: 15px;
  vertical-align: top;
  padding: 8px 12px;
}
.s-detail-left-card {
  border-radius: 10px;
}
.s-detail-r-card {
  border-radius: 10px;
}
.s-detail-r-card .ant-card-body {
  padding: 30px 6.5% 80px 30px;
}
.r-card-head {
  text-align: left;
  letter-spacing: -0.28px;
  color: #000000;
  font: normal normal 400 20px Gilroy SemiBold;
  margin-bottom: 20px;
}
.r-card-text {
  color: #000000;
  opacity: 0.5;
  font: normal normal 400 20px Gilroy SemiBold;
}
.custom-view-all {
  cursor: pointer;
}
.news-caursel-card {
  /* border: 1px solid; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  color: #1d2531 !important;
  /* height: 350px !important; */
  cursor: pointer;
}
.news-caursel-img {
  width: 261px;
  height: 200px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.news-caursel-container {
  height: 580px !important;
  width: 100% !important;
  border-radius: 6px;
  padding-top: 30px;
}
.news-title-carousel {
  /* display: flex; */
  justify-content: center;
  padding-top: 10px;
  padding-left: 55px;
  color: #1d2531 !important;
  font: normal normal 400 20px Gilroy SemiBold;
  width: 227px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.time-container-news-carousel {
  color: #6f727a;
  font: normal normal 400 20px Gilroy SemiBold;
  font-size: 15px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.date-news-carousel {
  color: #6f727a;
  font: normal normal 400 20px Gilroy SemiBold;
  font-size: 15px;
  display: flex;
  justify-content: center;
  padding-top: 40px;
}
.cGKbZh:hover:enabled,
.cGKbZh:focus:enabled {
  color: #fff;
  background-color: #ee5050 !important;
  box-shadow: 0 0 2px 0 #ee5050 !important;
  outline: none;
}
.ejXxNI {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: #ee5050 !important;
  font-size: 1.3em;
  content: '';
  height: 10px;
  width: 10px;
  box-shadow: 0 0 1px 3px rgb(103 58 183);
  border-radius: 50%;
  outline: none;
}
