.newsPageHeading {
  padding-top: 59px;
  padding-bottom: 52px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .newsPageFilterButton {
    width: 100%;
  }
  .newsPageLocationBox {
    width: 100%;
  }
  .displayNone {
    display: none;
  }
  .newsPageHeading {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  /* .newsCardParentBox {
    border: 2px solid red;
  } */
}
