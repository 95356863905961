.profilePhoto {
  object-fit: cover;
  width: 8vw;
  height: 8vw;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .displayNone {
    display: none !important;
  }
  .uploadImage {
    text-align: center !important;
    margin-top: 10px;
  }
  .uploadImageChild {
    border: 1px solid gray;
    color: rgb(67, 64, 64);
    margin-left: 5%;
    border-radius: 10px !important;
    padding: 20px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
