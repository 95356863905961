.marginTp {
  margin-top: 30px !important;
}
.pddingAround {
  padding: 2%;
}
.pddingAround1 {
  padding: 10px;
}
.headingCol {
  display: flex;
  justify-content: center;
  background-color: #f4f6f9;
}
.heading {
  font-size: 36px;
  letter-spacing: -0.49px;
  color: #24262b;
  opacity: 1;
  font-weight: bolder;
  display: flex;
  justify-content: center;
}
.sliderCol {
  display: block;
  background-color: #f4f6f9;
}
.titleCol {
  margin-top: 50px;
  display: flex;
  padding: 10px;
  width: 90%;
  background-color: #ed5050;
  border-radius: 20px;
}
.textValue {
  text-align: left;
  font: normal normal medium 28px/33px Gilroy;
  letter-spacing: -0.39px;
  color: white;
  font-size: 28px;
  font-weight: 600;
  opacity: 1;
}
.image {
  margin-top: 30px;
  width: 95%;
  margin-right: 5%;
  text-align: left;
  height: 95%;
  margin-bottom: 0px;
  object-fit: cover;
  border-radius: 15px;
}
.imageTwo {
  margin-top: 30px;
  width: 95%;
  margin-left: 5%;
  text-align: left;
  height: 95%;
  margin-bottom: 0px;
  object-fit: cover;
  border-radius: 15px;
}
.container {
  width: 90%;
  margin: auto;
}
.container1 {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  background-color: #f4f6f9;
}
.innerContainer {
  background-color: #fff;
  border-radius: 20px;
}
.title {
  padding: 10px;
  padding-bottom: 0px;
  text-align: left;
  font: normal normal medium 32px/38px Gilroy;
  color: #24262b;
  opacity: 1;
  font-size: 28px;
  font-weight: bold;
  opacity: 0.8;
}
.titleValue {
  padding: 10px;
  padding-bottom: 0px;
  text-align: left;
  font: normal normal 600 20px/25px Gilroy !important;
  letter-spacing: 0.18px;
  opacity: 0.7;
  font-size: 20px !important;
  line-height: 30px;
}

.titleValueIcons {
  font: normal normal 600 20px/25px Gilroy !important;
  letter-spacing: 0.18px;
  opacity: 0.7;
}
.iconsParentBox {
  margin-top: 10px;
}
.displayCenter {
  display: flex;
  justify-content: center;
}
.marginLeft {
  margin-left: 20px;
}
.paddingAll {
  padding: 20px;
}
.highlightDiv {
  margin-top: 100px;
  display: flex;
  background: #ed5150;
  color: white !important;
  padding: 1%;
  border-radius: 10px;
}
.highlightDiv h3 {
  color: white !important;
}
.displayFlexBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.allCards {
  border-radius: 10px;
  width: 90%;
  margin: 50px 5% 0% 5%;
  background: white;
  padding-bottom: 20px;
}
.featureImage {
  width: 60%;
  margin: 20px;
}
.iconImage {
  width: 100%;
}
.textCenter {
  text-align: center;
  padding: 15px;
  margin-left: 10px;
}
/*==================== below tablet screen =======================*/
@media screen and (max-width: 768px) {
  .featureImage {
    width: 100%;
    margin: 0px;
  }
  .image {
    width: 100%;
    height: 300px;
    margin-right: 0%;
  }
  .imageTow {
    width: 100%;
    height: 300px;
    margin-right: 0%;
  }
  .pddingAround {
    padding: 4%;
  }
  .highlightDiv {
    margin-top: 30px;
    padding: 3%;
  }
  .highlightDiv h3 {
    font-size: 18px !important;
  }
  .displayFlexBetween {
    flex-direction: column;
    justify-content: space-between;
  }
}
