.searchRow{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.sort{
    margin-top: 20px;
}
.cardRow{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.card{
    width: 100%;
    border-radius: 15px;
}
.image{
    width: 90%;
    height: 90%;
    max-height: 90px;
    border-radius: 10px;
    /* object-fit: cover; */
}
.topRow{
    padding-left: 20px;
}
.deliveryTime{
    margin-top: 10px;
}
.deliveryTime1{
    display: flex;
    align-self: 'self-start';
}
.price{
    display: flex;
}
.mainCol{
    width: 100%;
}
.imageContainer{
    width: 140px;
    height: 140px;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px;
}
.deleteIcon{
    position: absolute;
    z-index: 100000;
    top: -17px;
    left: 108px;
}
@media screen and (max-width: 600px) {
    .searchBar{
        width: 90%;
    }
    .sort{
        margin-top: 10px;
    }
    .cardRow{
        display: flex;
        justify-content: center;
    }
    .card{
        width: 90%;
    }
    .image{
        width: 100%;
        border-radius: 10px;
        /* object-fit: cover; */
    }
    .mainRow{
        display: flex;
        justify-content: center;
    }
    .mainCol{
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
    .addProduct{
        margin-left: 20px;
    }
    .buttonCol{
        display: flex;
        justify-content: center;
    }
    .button{
        width: 90% !important;
    }
}