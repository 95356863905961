.notIntrestedBtn {
  height: 100%;
  border-radius: 5px;
  width: 100%;
  font: normal normal 400 16px Gilroy SemiBold !important;
  letter-spacing: 1px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .mainContainer {
    width: 90% !important;
    margin: auto !important;
    margin-top: 20px !important;
    padding: 0px !important;
  }
  .card1 {
    margin-bottom: 30px;
  }
  .font18 {
    font-size: 18px !important;
  }
  .notIntrestedBtn {
    height: 100%;
    border-radius: 5px;
    width: 100%;
    font: normal normal 400 16px Gilroy SemiBold !important;
    letter-spacing: 1px;
  }
  .card2Padding {
    margin-top: -60px !important;
  }
}
