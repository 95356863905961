.mapCol {
  margin-top: 25px;
}
.jobpageCardImage {
  width: '90%';
  height: '100px';
  border-radius: '10px';
  object-fit: 'cover';
}
.imageContainer {
  padding: 10px 10px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 500px) {
  .jobpageHeader {
    margin-bottom: 400px;
  }
  .filterButton {
    width: 100%;
  }
  .locationInput {
    width: 100%;
  }
  .jobpageSecondRow {
    display: flex;
    flex-direction: column-reverse;
  }
  .jobpageLocationBox {
    height: 300px;
    margin-bottom: 300px;
  }
  .jobCardSection {
    margin-top: 200px;
  }
  .mapCol {
    width: 91%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 100px;
  }
  .jobCradHeading {
    font-size: larger;
  }
  .jobCradSubHeading {
    font-size: 15px;
    margin-left: 0px;
    line-height: 25px;
  }
  .jobPostedTime {
    margin-top: 10px;
  }
  .jobType {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .jobpageCardImage {
    /* border: 2px solid red; */
    margin-top: 20px;
    width: 110% !important;
    height: 110px !important;
  }
  .imageContainer {
    padding: 5px 20px;
    /* border: 2px solid blue; */
    padding-left: 0px;
  }
}
