.mainContainer{
    width: 100%;
}
.searchRow{
  margin-top: 20px;
}
.searchCol{
    display: flex;
    justify-content: center;
}
.sortCol{
    display: flex;
    justify-content: flex-end;
}
.image{
    width: 90%;
    height: 90%;
    max-height: 90px;
    /* object-fit: cover; */
    border-radius: 10px;
}
.button{
    background-color: #24262B !important;
    width: 192px;
    height: 50px;
    color:#ffffff !important;
    font: normal normal 600 16px/32px Gilroy Bold;
    float: right;
    border: 0 !important;
    outline: 0 !important;
    border-radius: 6px;
}
.deleteIcon{
    position: absolute;
    z-index: 100000;
    top: 1px;
    left: 120px;
}
.card{
    width: 100%;
    border-radius: 15px;
}
.imageContainer{
    width: 140px;
    height: 140px;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px;
}
@media screen and (max-width: 600px) {
    .mainContainer{
        margin-left: 50px;
        width: 100%;
    }
    .search{
        width: 90%;
    }
    .sortCol{
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
    }
    .cardCol{
        display: flex;
        justify-content: center;
    }
    .card{
        width: 90%;

    }
    .image{
        width: 100%;
        /* object-fit: cover; */
        border-radius: 10px;
    }
    .mainRow{
        margin: 30px;
        display: flex;
        justify-content: center;
    }
    .mainCol{
        margin-left: 20px;
        margin-right: 20px;
    }
    .buttonCol{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .button{
        width: 80%;
    }
}