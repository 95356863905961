

.card{
    width: 100%;
}
.bottomRow{
    margin-top: 40px;
}
.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.imageContainer{
    width: 140px;
    height: 140px;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px;
}
.deleteIcon{
    position: absolute;
    z-index: 100000;
    top: -17px;
    left: 109px;
}
.cardRow{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.card{
    width: 100%;
    border-radius: 10px;
}
@media screen and (max-width: 600px) {
    .sort{
        margin-top: 10px;
    }
    .searchCol{
        display: flex;
        justify-content: center;
    }
    .search{
        width: 90%;
    }
    .card{
        width: 90%;
    }
    .bottomRow{
        margin-top: 10px;
    }
    .mainCol{
        display: flex;
        justify-content: center;
    }
    .mainRow{
        width: 90%;
    }
    .addNews{
        margin-left: 23px ;
    }
    .buttonCol{ 
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .button{ width: 90% !important;}
}