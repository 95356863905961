.services-card {
  /* padding: 20px 30px; */
  margin-top: 20px;
  border-radius: 10px;
  /* cursor: pointer; */
}
.service-name {
  color: #24262b;
  font: normal normal 400 26px Gilroy SemiBold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: capitalize;
  cursor: pointer;
}
.person-detail {
  color: #000000;
  opacity: 0.5;
  font-size: 15px;
  font: normal normal 400 17px Gilroy SemiBold;
}
.product-detail {
  font: normal normal 400 19px/21px Gilroy Medium;
  font-size: 16px;
  opacity: 1;
  color: #7f7f7f;
}
.icon-col {
  display: flex;
  justify-content: flex-end;
}
.service-icons {
  color: #ee5050;
  font-size: 20px;
  padding: 0 15px 0 0;
  vertical-align: top;
}
.service-hidden-icons {
  visibility: hidden;
  cursor: pointer;
}
.service-detail-row:hover .service-hidden-icons {
  visibility: visible;
}
.service-detail-row:hover .hidden-role {
  visibility: hidden;
}
.interested-comp-desc {
  margin: 0%;
  color: black;
  font-weight: 900;
}
.chat-icon {
  height: 18px;
}
.product-name {
  font-size: 20px;
  text-transform: capitalize;
}
.service-s-desc {
  text-align: left;
  letter-spacing: -0.22px;
  color: #000000;
  opacity: 0.5;
  font: normal normal 600 16px/32px Gilroy Bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
  text-transform: capitalize;
}
.service-location {
  letter-spacing: -0.22px;
  color: #000000;
  opacity: 0.5;
  font: normal normal 600 16px/32px Gilroy SemiBold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  margin-top: -6px;
}
.services-pagination {
  margin-top: 20px;
  text-align: right;
}
.services-pagination .ant-pagination-item-active {
  background-color: black !important;
  border-radius: 50%;
  color: #ffffff !important;
  border: 0 !important;
  font-weight: bold !important;
}
.services-pagination .ant-pagination-item {
  background-color: transparent;
  border-radius: 50%;
  color: #000000;
  border: 0 !important;
  font-weight: bold;
}
.services-pagination .ant-pagination-item-link {
  border: 0 !important;
  background-color: transparent;
}
.intersted-icon {
  opacity: 1 !important;
}

.address-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ee5050;
  border-color: #ee5050;
}
.address-checkbox:hover {
  border-color: #ee5050 !important
}
.address-checkbox  {
  /* margin-right: 15px; */
  color:rgba(0,0,0,0.5);
  font-size: 15px;
  font: normal normal 400 17px Gilroy SemiBold
  
}
