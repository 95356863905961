.changePasswordButton {
  display: none;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .profileMainContainer {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    /* border: 2px solid red; */
  }
  /* .profileHeading {
    border: 2px solid red;
    background: white;
    width: 200% !important;
  } */
  .changePasswordButton {
    display: block;
    background: white;
    font: normal normal 400 18px/22px Gilroy semiBold;
    color: #ed5150;
    border-radius: 7px;
    height: 40px;
    min-width: 200px;
    border: 1px solid gray;
  }
  .changeButtonOnSmallSceen {
    display: none !important;
  }
  .fromPaddingRight {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .formPaddingLeft {
    padding-left: 0px !important;
    /* border: 2px solid red; */
  }
  .viewPublicProfile {
    text-align: center;
    padding-right: 13%;
    margin-top: -30px;
  }
  .candidateProfile {
    display: flex;
    justify-content: center;
    padding-left: 50px;
  }
  .profileContainer {
    margin-bottom: -20px;
  }
  .marginTopinTextBox {
    margin-top: -20px;
  }
}
