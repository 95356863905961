.searchRow{
    margin-top: 20px;
}
.searchCol{
    display: flex;
    justify-content: center;
}
.image{
        width: 90%;
        height: 90%;
        max-height: 90px;
        /* object-fit: cover; */
        border-radius: 10px;
}
.nameRow{
    margin-left: 11px ;
}
.location{
    width: 100%;
    height: 50px;
}
.cardCol{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.card{
    border-radius: 15px;
    width: 100%;
}
.imageContainer{
    width: 140px;
    height: 140px;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px;
}
.deleteIcon{
    position: absolute;
    z-index: 100000;
    top: 2px;
    left: 121px;
}
@media screen and (max-width:600px) {
    .eventTop{
        margin-left: 10px;
    }
    .search{
        width: 90%;
    }
    .sortCol{
        margin-top: 10px;
    }
    .cardCol{
        display: flex;
        justify-content: center;
    }
    .image{
        width: 100%;
        /* object-fit: cover; */
        border-radius: 10px;
    }
    .card{
        width: 90%;
    }
    .mainRow{
        display: flex;
        justify-content: center;
    }
    .uploadImageCol{
        width: 80%;
    }
    .nameRow{
        margin-left: 10px ;
    }
    .input{
        margin-right: 20px ;
    }
    .buttonCol{
        display: flex;
        justify-content: center;
    }
    .button{
        width: 90% !important;
    }
}