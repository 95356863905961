.serviceCardImagesContainer {
  /*border: 2px solid blue;*/
}
.serviceCardDetailsContainer {
  padding-left: 20px;
}
.serviceCradMainContainer {
  width: 90%;
  margin: auto;
}
.serviceCardImages {
  width: 100%;
  height: 120px;
  border-radius: 10px;
  object-fit: cover;
}
.mapIcons {
  text-align: right;
  margin-top: -5px;
}
.sponsored {
  color: #689ADD !important;
  font: normal normal 400 14px/26px Gilroy SemiBold !important;
  display: flex !important;
  justify-content: flex-end !important;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .serviceCradMainContainer {
    padding: 0px !important;
    width: 90%;
    margin: auto;
  }
  .serviceCardImagesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mainServiceCardRow {
    margin: -15px;
  }
  .serviceCardImages {
    height: 120px;
    width: 100%;
  }
  .mapIcons {
    text-align: right;
    margin-top: -15px;
    margin-bottom: 10px;
  }
  .sponsored {
    color: #689ADD !important;
    font: normal normal 400 14px/26px Gilroy SemiBold !important;
    display: flex !important;
    justify-content: flex-end !important;
    
  }
}
