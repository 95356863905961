@media (min-width: 576px) {
  .layout-padding-margin-1 {
    /*padding:0 30px 0 120px !important;*/
    margin-top: 30px;
  }
  .layout-padding-margin-2 {
    margin-top: 30px !important;
    padding-right: 7.7%;
  }
}
@media (max-width: 1028px) {
  .responsive-div {
    transform-origin: top left;
    width: 143%;
    transform: scale(0.7);
    height: 100vh;
  }
}
.navbar-nav {
  flex-direction: inherit !important;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
}
