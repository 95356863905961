.services-card{
    /* padding: 20px 30px; */
    margin-top: 20px;
    border-radius: 10px;
    /* cursor: pointer; */
}
.service-name{
    color: #24262B;
    font: normal normal 600 26px/32px Gilroy Bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;   
    text-transform: capitalize;
    cursor: pointer;
}
.icon-col{
    display: flex;
    justify-content: flex-end;
}
.service-icons{
    color:#EE5050;
    font-size: 20px;
    padding: 0 15px 0 0 ;
    vertical-align: top;
}
.service-hidden-icons{
    visibility: hidden;
    cursor: pointer;
}
.service-detail-row:hover  .service-hidden-icons{
    visibility: visible;
}
.service-s-desc{
    text-align: left;
    letter-spacing: -0.22px;
    color: #000000;
    opacity: 0.5;
    font: normal normal 600 16px/32px Gilroy Bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 10px;
    text-transform: capitalize;
}
.service-location{
    letter-spacing: -0.22px;
    color: #000000;
    opacity: 0.5;
    font: normal normal 600 16px/32px Gilroy SemiBold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    margin-top: -6px;

}
.paginate-container {
    display: flex;
    justify-content: center;
    padding: 5px;
  
  }
  .page-item.active .page-link
{
  color: #FFFFFF !important;
  background-color: #24262B !important;
  border-radius: 50% !important;
}
.page-link 
{
  background-color: #F4F6F9 !important;
  border-radius: 50% !important;
  color: #24262B !important;
  font-weight: 600 !important;
  border:0px !important;
}
.services-pagination{
    margin-top: 20px;
    text-align: center;
}
.services-pagination .ant-pagination-item-active, .services-pagination .ant-pagination-item a:active, .services-pagination .ant-pagination-item a:visited{
    background-color: black !important;
    border-radius: 50%;
    color:#ffffff !important;
    border:0 !important;
    font-weight: bold !important;
}
.services-pagination .ant-pagination-item{
    background-color: transparent;
    border-radius: 50%;
    color:#000000;
    border:0 !important;
    font-weight: bold;
}
.services-pagination .ant-pagination-item-link{
    border: 0 !important;
    background-color: transparent;
}
.no-data-div{
    text-align: center;
    padding-top: 50px;
    font: normal normal 600 20px/32px Gilroy Bold;

}