.dropDownMenu {
  margin-bottom: 40px;
}
.timePeriodSelect {
  cursor: pointer;
}

/*================ for mobile screens ====================*/
@media screen and (max-width: 600px) {
  .mainContainer {
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
  }
}
