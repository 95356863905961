.cardsImage {
  width: 80%;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}
.serviceIcon {
  height: 16px;
}
.thirdRowCard {
  margin-top: 10px;
}
.cardsResume {
  margin-left: -2% !important;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .candidateHeading {
    display: none;
  }
  .mainContainer {
    width: 90%;
    margin: auto;
  }
  .serviceCardsParents {
    padding: 0px !important;
  }
  .serviceCardsParentslayer2 {
    padding: 0px !important;
    margin-bottom: -20px;
  }
  .cardsImage {
    margin-left: -15px;
    width: 115%;
    height: 110px;
    object-fit: cover;
    border-radius: 10px;
  }
  .cardsResume {
    margin-left: -4% !important;
  }
  .serviceIcon {
    height: 16px;
    margin-top: 2px;
  }
  .thirdRowCard {
    margin-top: 5px;
  }
  .locationSection {
    margin-top: -7px;
  }
}
