.page-number-btn {
  margin: 10px;
  border-radius: 50%;
  background-color: #24262b;
  color: #fff;
  padding: 5px;
  width: 32px;
  height: 32px;
}
.date-container {
  display: flex;
  justify-content: flex-end;
}
.job-card-container {
  height: 142px;
  width: 100%;
}
.job-inner-container {
  padding-left: 10px;
}
.selectedPage {
  background-color: black;
}
.applied-count-container {
  background-color: #f7dcdf;
  padding: 4px 10px 4px 10px;
  border-radius: 2px;
  display: initial;
  font: normal normal 400 12px/14px Gilroy semiBold;
}
.imageContainer .ant-image-img{
  object-fit: contain !important;
}
.activePage {
  margin: 10px;
  border-radius: 50%;
  background-color: #24262b;
  color: #fff;
  padding: 5px;
  width: 32px;
  height: 32px;
}
.pagination-container {
  margin: 10px;
  cursor: pointer;
}
.pagination-side-btn {
  color: #d4d6d9;
}
.ant-switch-checked {
  background-color: #36b37e;
}
.add-job-container .add-job-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-job-container .job-field-container {
  display: flex;
  justify-content: space-between;
}
.add-job-container .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.add-job-container .s-input-fields {
  font: normal normal 400 18px/32px Gilroy SemiBold;
  color: #24262b;
  width: 100%;
}
.add-job-container .cover-img-job {
  border: 3px solid red;
}
.manage-user-card:hover {
  box-shadow: 0px 2px 24px #000 2a;
  margin: 2px;
}
.company-presentation-container .profile-media {
  object-fit: cover !important;
}
.company-presentation-container .ant-image-img {
  object-fit: cover;
}
.manage-user-icons {
  color: #ee5050;
  font-size: 20px;
  padding: 100px 20px 0 0;
  vertical-align: top;
}
.calender-icon-field .ant-picker-suffix {
  color: #ee5050;
}
.company-media-thumbnail .react-thumbnail-generator img {
  width: 100%;
  height: 100%;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.div-img-comp-media-thumbnail-button {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  color: black;
  font-size: 16px;
  padding: 16px 32px;
}
.image-media-container {
  position: relative;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.company-media-thumbnail .react-thumbnail-generator img:hover {
  opacity: 0.3;
}
.image-media-container:hover .image {
  opacity: 0.3;
}
.image-media-container:hover .middle {
  opacity: 1;
}
.company-media-thumbnail:hover .middle {
  opacity: 1;
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  line-height: 1.5715;
  background-color: white;
  height: 25vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.claim-box {
  border: solid 1px black;
  background-color: white;
  padding: 10px;
  width: 60%;
  margin: 30px 0 0 150px;
}
.claim-box button {
  width: 100%;
}
.claim-title {
  font-weight: bolder;
  font: normal normal 400 17px Gilroy SemiBold;
  font-size: 24px;
  margin-bottom: 0.5rem;
}
.claim-subtext {
  color: gray;
  font: normal normal 400 17px Gilroy SemiBold;
}
.claim-modal .modal-content {
  height: auto !important;
}
.claim-modal .modal-body {
  height: auto;
}
