.eventCardsCol2 {
  padding-left: 20px;
}

.mapIcons {
  margin-left: 25px;
  display: flex ;
  justify-content: flex-end;
  font-size: '18px';
}

.mainContainerOfServiceCards {
  margin: 0px 40px 15px 40px;
}
.eventPageCardsImage {
  width: 100%;
  height: 120px;
  border-radius: 10px;
  object-fit: cover;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .eventPageLocationBox {
    width: 100%;
  }
  .eventPageCalenderParentBox {
    display: block;
  }
  .eventPageCalender {
    width: 100%;
  }
  .eventPageFilterButton {
    width: 100%;
  }
  .eventPageMainContainer {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin: auto;
  }
  .eventPageMapSection {
    margin-bottom: 320px;
  }
  .eventPageCardsNumberOfData {
    padding-left: 10px !important;
  }
  .mainContainerOfServiceCards {
    margin-top: -15px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
    padding-bottom: 0px !important;
    margin-bottom: 25px !important;
  }
  .eventCardsCol2 {
    padding-left: 10px;
  }
  .mapIcons {
    margin-left: '0px';
    display: 'flex';
    justify-content: 'end';
    font-size: '18px';
  }
  .eventImageParent {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .eventPageCardsImage {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    object-fit: cover;
  }
}
