.selectInputBox {
  padding: 15px 14px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .paddingLft {
    padding-left: 0px !important;
  }
  .paddingRyt {
    padding-right: 0px !important;
  }
  .btn {
    width: 90% !important;
    margin: auto;
    border: 2px solid red;
  }
  .inputBox {
    width: 100% !important;
    padding: 7px 8px;
  }
  .selectInputBox {
    padding: 6px 6px;
    /* border: 2px solid red !important; */
    /* width: 100% !important; */
  }

  .selectInputBoxOptions {
    border: 2px solid red !important;
    font-size: 12px !important;
    width: 50% !important;
  }
}
