.parentBox {
  width: 100vw;
  height: 100vh;
  background-color: #24262b66;
  backdrop-filter: blur(6px);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}
.childBox {
  width: 30%;
  height: 15vw;
  background-color: white;
  border-radius: 10px;
  opacity: 1;
  margin: 10vw 0 0 35vw;
}
.closeUpIcon {
  height: 2vw;
  padding-left: 27vw;
  padding-top: 1vw;
}
.popupText {
  font-size: 1.5vw;
  font-weight: 900;
  margin-left: 10px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .parentBox {
    width: 100% !important;
    padding: 10px !important;
  }
  .childBox {
    margin-top: 150px !important;
    margin-left: 15% !important;
    width: 70%;
    height: 40vw;
  }
  .closeUpIcon {
    height: 2vw;
    padding-left: 85vw;
    padding-top: 1vw;
    margin-bottom: 20px;
  }
  .popupText {
    font-size: 18px;
    font-weight: 900;
    margin-left: 10px;
  }
}
