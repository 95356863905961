.mainContainer {
  width: 95% !important;
  margin: auto !important;
}
.headingText {
  font: normal normal 400 35px/40px Gilroy semiBold;
  letter-spacing: -0.36px;
  color: #24262b;
  text-align: center;
}
.headerBg {
  padding: 5%;
}
.mainBody {
  background-color: #f4f6f9;
  padding: 0px, 5%, 0px, 5%;
}
.welcomeText {
  background: white;
  padding: 3%;
  border-radius: 15px;
  color: #24262b;
  font: normal normal normal 20px/30px Gilroy !important;
}
.cardBackground {
  background-color: white;
  border-radius: 15px;
}
.cardPadding {
  padding: 3%;
}
.headerImage {
  margin: 0px 0px 40px 0px;
  border-radius: 15px;
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.cardMainRow {
  margin-top: 50px;
  display: flex;
  flex-direction: row !important;
  gap: 5%;
}
.cardChild {
  padding: 3%;
}
.cardHeadign {
  font: normal normal 800 22px/25px Gilroy medium !important;
}
.cardText {
  font: normal normal 400 18px/20px Gilroy medium !important;
  opacity: 0.8;
}
.badge {
  margin-top: 0px;
  margin-right: 5px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 768px) {
  .welcomeText {
    padding: 5%;
  }
  .cardText {
    font: normal normal 100 18px/20px Gilroy medium !important;
  }
  .headingText {
    font: normal normal 400 30px/35px Gilroy semiBold;
    letter-spacing: -0.36px;
    color: #24262b;
    text-align: left;
  }
  .headerImage {
    height: 300px;
  }
  .mainContainer {
    width: 90% !important;
    margin: auto !important;
  }
  .headerBg {
    background: #f4f6f9;
    padding: 7%;
    padding-left: 0%;
  }
  .cardPadding {
    padding: 5%;
  }
}
