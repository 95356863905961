.productPageServiceCards {
  margin: 0 40px 15px 40px;
  cursor: pointer;
}

.productLogoParentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.productsCartParentCOntainer {
  display: flex;
  gap: 15px;
}

.mapIcons {
  margin-left: 30px;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .productPageSearchBar {
    /* border: 2px solid black; */
    padding: 20px 5% 16px 5% !important;
    background: #ffffff;
    border-top: 1px solid #d1d1d1;
  }
  .filterButton {
    padding: 7px 10px 7px 30px;
    width: 100%;
  }

  .locationSectionProductPage {
    width: 100%;
  }

  .jobPageMainContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 15px;
  }

  .productPageMapSection {
    margin-top: 10px;
    width: 90%;
    margin: auto;
    height: 400px;
    margin-bottom: 300px;
  }
  /* To increase heigth of the textbox */
  .paddingInJobSearch {
    padding: 10px 10px 10px 30px;
    border: 2px solid red;
  }

  .productPageServiceCards {
    margin: 15px 25px 15px 25px;
    cursor: pointer;
  }
  .productLogo {
    height: 130px;
    width: 100%;
    border-radius: 10px;
  }
  .productsCartParentCOntainer {
    display: flex;
    gap: 15px;
  }
  .mapIcons {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    font-size: 18px;
    margin-top: -20px;
  }
  .mainContainerOfProductPage {
    height: auto !important;
  }
}
