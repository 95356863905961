.Subcard{
    height:163px;
    width: 1115px;
    background-color: rgb(255, 255, 255);
    display: flex;
    margin-top: 20px;
     border-radius: 12px;
padding: 10px 25px 10px 20px;
flex-direction: row;
    align-items:center;
    padding-right: 0px;
}
.Subimg{
        height: 110px;
    width: 120px;
    margin-right: 20px;
    border-radius: 12px;
    
}
.textSub{
    display: flex;
    flex-direction: column;
 
}
.text1{
    display: flex;
    flex-direction: row;
       justify-content: space-between;
       align-items: center;
}
.middleSubcard{
    display: flex;
    flex-direction: row;
       justify-content: space-between;
       align-items: center;
}
.lastSubcard{
    display: flex;
    flex-direction: row;
}


.SubcardHeading{
        font-size: 2.5rem;
    /* line-height: 2rem; */
    color: #24262b;
    font: normal normal 400 25px/10px Gilroy SemiBold;
  
    cursor: pointer;
}

.SubApplied{
        border-radius: 2px !important;
    border: 0 !important;
    font: normal normal 400 14px/26px Gilroy SemiBold;
    height: 26px;
    padding: 2px 8px;
    max-width: 340px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
        color: #d4380d;
    background: #fff2e8;
    /* margin-left: 80px; */
}

.SubAdded{
padding-left: 20px;
color: rgb(44, 43, 43);
}
.SubContract{
    color: rgb(44, 43, 43);
}

.SubName{
      font: normal normal 400 14px/26px Gilroy SemiBold;
}
.Subdates{
padding-left: 20px;
color: rgb(44, 43, 43);
}
.SubContract{
    color: rgb(44, 43, 43);
}

.SubName{
      font: normal normal 400 14px/26px Gilroy SemiBold;
}