.nextBilling {
  text-align: center;
  display: flex;
  justify-content: center;
}
.spoceredSectionTotalAmount {
  font-size: 1.5vw;
  margin: 1vw 0 0 0;
  text-align: right;
}
.profileImage {
  object-fit: cover;
  width: 8vw;
  height: 8vw;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .mainContainer {
    width: 90%;
    margin: auto;
    margin-top: 30px;
  }
  .headingText {
    font: normal normal 400 18px/22px Gilroy semiBold !important;
  }
  .billingMT {
    margin-top: 25px !important ;
  }
  .billingMarginBtm {
    margin-bottom: 30px !important;
  }
  .nextBilling {
    text-align: left;
    padding-right: 0px;
    padding-left: 5px;
  }
  .nextBillingAmount {
    margin-top: -20px !important;
    margin-bottom: 10px !important;
  }
  .nextBillingAmountText2 {
    margin-top: -20px !important;
    margin-bottom: 10px !important;
  }
  .spoceredSectionTotalAmount {
    text-align: left;
  }
  .btnMt {
    margin-top: 10px;
  }
  .manageUserParent {
    width: 90%;
    margin: auto;
  }
  .profileImage {
    object-fit: cover;
    width: 100%;
    height: 100px;
  }
  .profileImageParent {
    margin-top: 0px !important;
    padding: 10px !important;
  }
  .manageUserCard {
    padding: 10px !important;
  }
  .emailText {
    margin-top: 10px;
    margin-bottom: -5px;
  }
  .displayNoneSM {
    display: none !important;
  }
  .manageUserHeader {
    background: white;
    margin-bottom: 15px;
  }
  .sortBox {
    margin-top: 10px;
    width: 45% !important;
    margin-left: 55% !important;
  }
}
