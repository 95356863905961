.social-modal > .ant-modal-content > .ant-modal-body {
  height: 100vh !important;
}
.create-user-modal > .ant-modal-content > .ant-modal-body {
  height: auto !important;
  padding-bottom: 10px;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .social-modal > .ant-modal-content > .ant-modal-body {
    height: auto !important;
  }
  .right-modal-w100 {
    width: 100% !important;
  }
  .right-modal-btn {
    margin-left: 50px !important;
  }
}
