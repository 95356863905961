.planCard1 {
  background: #ffffff;
  border-radius: 10px;
  margin-left: 30px;
  padding: 0px;
}
.planCard2 {
  background: #ffffff;
  border-radius: 10px;
  margin-left: 30px;
  padding: 0px;
}
.planCardsParents {
  width: 90%;
  margin: auto;
  background-color: #f4f6f9;
  padding-top: 60px;
  padding-bottom: 50px;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .planCard1 {
    border-radius: 10px;
    margin-left: 0px;
    padding: 0px;
  }
  .planCard2 {
    margin-top: 30px;
    background: #ffffff;
    border-radius: 10px;
    margin-left: 0px;
    padding: 0px;
  }
}
