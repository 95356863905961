.card1Row1 {
  margin: 40px 0 28px;
}
.sendMsgBtn {
  background-color: #24262b !important;
  height: 50px;
  width: 90%;
  font: normal normal 400 16px Gilroy SemiBold;
  color: #ffffff !important;
  border-radius: 6px;
  outline: 0 !important;
  border: 0 !important;
}
.intrestedBtn {
  height: 100%;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  background-color: white;
  color: black;
  height: 50px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .mainContainer {
    width: 90% !important;
    margin: auto !important;
    padding: 0px !important;
    margin-bottom: -40px !important;
  }
  .headerCard {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .card1Row1 {
    margin: 0px 0 0px;
  }
  .sendMsgBtn {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    font: normal normal 400 18px Gilroy SemiBold;
  }
  .intrestedBtn {
    font: normal normal 400 18px Gilroy SemiBold;
  }
  .description {
    color: #000000;
    opacity: 0.7;
    font: normal normal 400 16px Gilroy SemiBold !important;
  }
}
