/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .mainContainer {
    width: 90% !important;
    margin: auto;
    padding: 0px !important;
    margin-bottom: -70px;
    margin-top: 20px;
  }
  .recentJobService {
    margin-top: 25px;
  }
  .DateOfEvents {
    margin-top: 10px;
  }
  .likedSection {
    margin-bottom: 10px;
  }
}
