.change-password-btn {
  width: 200px;
  background-color: #ffffff99;
  cursor: pointer;
  font: normal normal 400 16px/18px Gilroy Bold;
  color: #ee5050;
  height: 37px;
  justify-content: center;
  border-radius: 19px;
  display: flex;
  align-items: center;
}
.change-password-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 64px;
  align-items: center;
}
.company-profile-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.input-field-custom-select-1-right > .ant-select-selector {
  padding: 11px;
  font: normal normal 400 16px/18px Gilroy Bold;
  letter-spacing: -0.19px;
  color: #ee5050;
  border: unset !important;
  border-radius: 6px !important;
}
.input-label-field-custom-select-type-2 > .ant-select-selector {
  padding: 11px;
  font: normal normal 400 16px/18px Gilroy Bold;
  letter-spacing: -0.19px;
  color: #ee5050;
  border: unset !important;
  border-radius: 6px !important;
}
.input-label-field-custom-select-type-2 {
  width: 100%;
}
.input-label-field-custom-select-type-3 > .ant-select-selector {
  padding: 11px !important;
  font: normal normal 400 16px/18px Gilroy Bold;
  letter-spacing: -0.19px;
  border: unset !important;
  border-radius: 6px !important;
}
.input-label-field-custom-select-type-4 > .ant-select-selector {
  border: 0 !important;
  height: 52px !important;
  padding: 12px !important;
  text-align: left;
}
.input-field-custom-type-1-right-select {
  font: normal normal 400 18px/22px Gilroy semiBold;
  color: #24262b !important;
  height: 52px;
  border-radius: 6px;
  border: none;
  width: 90%;
  background-color: white;
}
.img-uploaded {
}
.picture-card .ant-upload-list-item-error {
  border: 0 !important;
}
.image-container {
  position: relative;
  margin-top: 50px;
  width: 300px;
  height: 300px;
}
.image-container .ant-image-img{
  object-fit: contain !important;
}
.image-container > a {
  padding: 0 !important
}
.image {
  position: absolute;
  width: 300px;
  height: 400px;
  left: 0;
}
.delete-btn-info-media {
  position: absolute;
  width: 300px;
  /* left: 80px; */
  /* top: 1px; */
  font-size: 1.2rem;
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
  z-index: 1;
  color: #ee5050 !important;
}
.image-container:hover .delete-btn-info-media {
  opacity: 1;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 200px;
  padding: 5px;
  row-gap: 0.2em;
  column-gap: 0.2em;
}
.profile-media-thumbnail .react-thumbnail-generator img {
  width: 100px;
  height: 100px;
  opacity: 1;
 
  /* display: block; */
  /* margin-top: 17px; */
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.media-text {
  color: black;
  font-size: 16px;
  /* padding: 16px 32px; */
}
.container {
  position: relative;
}
.media-middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.mediaUpload > .ant-upload-list > .ant-upload-list-picture-card{
  display: flex !important;
  flex-wrap: wrap !important;
}
.profile-media-thumbnail .react-thumbnail-generator img:hover {
  opacity: 0.3;
}
.container:hover .image {
  opacity: 0.3;
}
.container:hover .profile-media-thumbnail:hover {
  opacity: 1;
}
.profile-media-thumbnail:hover .media-middle {
  opacity: 1;
}
