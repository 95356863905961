.back-icon {
  vertical-align: text-bottom;
  color: #afb5c0;
  cursor: pointer;
}
.back-btn {
  cursor: pointer;
  color: #afb5c0;
  margin-left: 10px;
}
.add-service-form,
.add-form {
  margin-top: 30px;
}
.add-service-form label,
.add-form label {
  color: #24262b;
  opacity: 0.6;
  font: normal normal 400 16px/32px Gilroy SemiBold;
  width: 100%;
}
.currency-select .ant-select-selector {
  background-color: #ee5050 !important;
  border-radius: 6px 0 0 6px !important;
  border: 0 !important;
  height: 50px !important;
  font: normal normal 400 18px/32px Gilroy SemiBold;
  color: #24262b !important;
  padding: 12px 15px !important;
}
.currency-select .ant-select-selector,
.currency-select .ant-select-selector .ant-select-selection-item {
  /* padding: 12px 20px 12px 10px; */
  height: 50px !important;
  color: #ffffff !important;
}
.s-input-fields {
  font: normal normal 400 18px/32px Gilroy SemiBold;
  color: #24262b;
  width: 100%;
}
.s-input-fields,
.s-input-fields .ant-input,
.s-input-fields .ant-select-selector {
  background-color: #ffffff;
  border: 0 !important;
  border-radius: 6px !important;
  height: 50px !important;
}
.s-input-fields .ant-select-selector,
.s-input-fields .ant-select-selector .ant-select-selection-item {
  padding: 10px;
  height: 50px !important;
}
.ant-select-multiple .ant-select-selector .ant-select-selection-item {
  padding: 0px 10px;
  height: 30px !important;
  background: #ee505010 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #ee5050;
  font: normal normal 400 12px/32px Gilroy SemiBold;
}
.s-input-fields .ant-input-group-addon {
  height: 37.97px;
  background-color: red;
  border: 0 !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #ffffff !important;
  width: 100% !important;
}
.s-input-fields .ant-input-group-addon .ant-select-selection-item {
  padding: 11px;
  padding-right: 18px;
}
.s-input-fields .ant-input-group-addon .ant-select,
.s-input-fields .ant-input-group-addon .ant-select-arrow {
  font-weight: bold;
  color: #ffffff !important;
}
.s-input-fields .ant-upload,
.s-input-fields .ant-upload button {
  width: 70%;
  background-color: #ffffff;
  border-radius: 5px;
  height: 50px !important;
}
.s-input-fields .ant-upload button {
  border: 1px dashed #979797;
  width: 100%;
}
.s-file-upload {
  position: absolute;
  right: 0;
  top: 5px;
  border-radius: 25px;
  height: 40px !important;
  border-color: #ee5050;
  color: #ee5050;
  opacity: 1;
  font-weight: bold;
}
.short-desc-input {
  background-color: #ffffff !important;
}
.price-input-field {
  font: normal normal 400 18px/32px Gilroy SemiBold;
  color: #24262b;
  /* width: 100%; */
  border: 0px !important;
  height: 50px !important;
  width: 400px !important;
  max-width: 95%;
}
.ant-input-group.ant-input-group-compact {
  display: flex;
}

@media screen and (max-width: 1440px) {
  .price-input-field {
    font: normal normal 400 18px/32px Gilroy SemiBold;
    color: #24262b;
    width: 100%;
    border: 0px !important;
    height: 50px !important;
    width: 365px !important;
  }
}
