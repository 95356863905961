/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .allTabsComponents {
    margin-bottom: 10px;
    background-color: transparent !important;
    padding-top: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
