.services-card .ant-card-body {
  padding: 23px 25px 18px 30px;
}
.s-search-bar {
  padding: 12px 7.64% 16px 3.47%;
  background: #ffffff;
  border-top: 1px solid #d1d1d1;
}
.s-search-select {
  width: 100%;
}
.s-search-inp .ant-input-affix-wrapper {
  border: 1px solid #d1d1d1;
  border-radius: 10px !important;
  opacity: 0.7 !important;
  font: normal normal 400 16px Gilroy SemiBold;
  height: 40px;
}
.s-search-inp .ant-input-group-addon {
  display: none;
}
.s-search-select .ant-select-selector {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d1d1d1 !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  height: 40px !important;
}
.s-search-select .ant-select-selection-item,
.s-search-select .ant-select-selection-placeholder {
  padding: 4px !important;
  font: normal normal 400 16px Gilroy SemiBold;
}
.card-div-head {
  font: normal normal 400 24px Gilroy SemiBold;
  padding: 23px 50px;
  margin: 0;
}
.s-price-tag {
  border-radius: 2px !important;
  border: 0 !important;
  font: normal normal 400 14px/26px Gilroy SemiBold;
  height: 26px;
  padding: 2px 8px;
  max-width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-company-name {
  color: #24262b;
  opacity: 1;
  font: normal normal 400 13px/15px Gilroy SemiBold;
  text-transform: uppercase;
}
.eye-icon,
.like-icon {
  color: #ee5050;
  vertical-align: super;
  padding: 2px;
}
.like-eye-text {
  text-align: left;
  letter-spacing: 0px;
  color: #7b7b7b;
  font: normal normal 400 13px/26px Gilroy SemiBold;
  vertical-align: super;
  padding: 0 20px 0 5px;
}
.product-name {
  color: #24262b;
  font: normal normal 400 18px/22px Gilroy SemiBold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: capitalize;
  cursor: pointer;
}
.product-location {
  letter-spacing: -0.22px;
  color: #000000;
  opacity: 0.5;
  font: normal normal 600 16px/19px Gilroy SemiBold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  /* margin-top: -6px; */
}

.custom-tag-hover:hover {
  transform: scale(1.1);
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .services-card {
    padding: 15px;
  }
  .eventpage-maincontainer {
    padding: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-bottom: 50px !important;
  }
}
