
.subcontractor{
    overflow: scroll;
}
.topSub{
display: flex;
flex-direction: row;
justify-content: space-between;
}
.subHeading{
    font-family: "Gilroy Bold";
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
}
.openButton{
    font-weight: 400;
    color: white;
    text-align: center;
    width: 192px;
    height: 50px;
    vertical-align: middle;
    background-color: #212529;
       font-family: "Gilroy Bold";
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
  
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.middleSub{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-top: 25px;
}
.subSearchBox{
    box-sizing: border-box;
    width: 300px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    /* width: 100%;  */
   height: 40px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: all 0.3s;
    /* margin-right: 10px; */
}
.drop-down{
    width: 145px;
    display: inline-block;
    border-radius: 6px;
}

.postButton{
    background-color: rgb(238, 80, 80);
    height: 40px;
    text-align: center;
    border-radius: 6px;
    color: white;
    text-align: center;
    /* padding: 0 20px; */
    
    font-family: "Gilroy Bold";
    font-size: 1 rem;
}

.postedButton{
    background-color: rgb(238, 80, 80);
    height: 40px;
    text-align: center;
    border-radius: 6px;
    color: white;
    text-align: center;
    /* padding: 0 20px; */
    margin-left: 400px;
    /* float: right; */
    font-family: "Gilroy Bold";
    font-size: 1 rem;
}