.heading{
    font-weight: 700px;
    margin-top: 20px;
    margin-left: 20px;
    text-transform: capitalize;
    font: normal normal 400 25px/14px Gilroy semiBold;
}
.searchBar{
    height: 40px;
    width: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    opacity: 0.7;
}
.sideMargin{
    margin-left: 5px;
}
.text{
    text-align: left;
    letter-spacing: -0.22px;
    color: #000000;
    opacity: 0.5;
    font: normal normal 600 16px Gilroy Bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: capitalize;
}
.searchCol{
    display: flex;
    justify-content: center;
}
.mainCol{
    display: flex;
    justify-content: center;
    align-items: center;
}
.location{
    text-align: left;
    color: #000000;
    opacity: 0.5;
    font: normal normal 600 16px Gilroy Bold;
    text-transform: capitalize;
}
.image{
        width: 90%;
        height: 90%;
        max-height: 90px;
        object-fit: cover;
        border-radius: 10px;
}
.image1{
    width: 90%;
    height: 90px;
    /* object-fit: cover; */
    border-radius: 10px;
}
.tag{
    margin-left: 25px;
    max-width: 100%;
}
.cost{
    text-transform: capitalize;
    font-weight: bold;
    color: #ED5050;
    margin-top: 4px;
}
.title{
    color:#ee5050;
    text-transform: capitalize;
    font-weight: bold;
}

.removePad{
    padding: -10px;
}
.title1{
    color:#ee5050;
    text-transform: capitalize;
    font-weight: bold;
    margin-left: 5px;
}
.titleValue{
    font-family: 'Gilroy Bold';
    font-size: 15px;
    font-weight: 'bold';
    font-style: 'normal';
    margin-left: 5px;
}
.titleValue1{
    font-family: 'Gilroy Bold';
    font-size: 15px;
    font-weight: 'bold';
    font-style: 'normal';
    /* margin-left: 5px; */
}
.card{
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    margin: 20px 0px 15px 0px ;
}
@media screen  and (max-width: 768px){
    .heading{
        font-weight: 800;
        margin-top: 10px;
        text-transform: capitalize;
        margin-left: 30px;
    }
    .searchBar{
        height: 50px;
        width: 90%;
    }
    .cardCol{
        display: flex;
        justify-content: center;
    }
    .card{
        width: 90%;
    }
    .tag{
        display: none;
    }
    .titleDateCol{
        background-color: #ffedee;
        margin: 10px 0px 0px 0px;
        border-radius: 20px;
    }
    .image{
        width: 100%;
        /* object-fit: cover; */
        border-radius: 10px;
    }
    .image1{
        width: 100%;
        height: 100px;
        /* object-fit: cover; */
        border-radius: 10px;
}
}