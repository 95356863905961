.payment-container {
  height: 82px;
  background-color: #f4f6f9;
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  font: normal normal 600 18px/22px Gilroy;
  justify-content: space-between;
  letter-spacing: 0px;
  color: #24262b;
}
.payment-container .dollor-sign {
  font: normal normal normal 30px/35px Gilroy;
  padding-bottom: 5px;
  margin: 10px;
}
.new-payment-amount {
  color: #ee5050;
  opacity: 1 !important;
  font: normal normal bold 30px/38px Gilroy;
}
.payment-container .amount {
  color: #ee5050;
  opacity: 1 !important;
  font: normal normal bold 48px/58px Gilroy;
}
.select-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
}
.select-card-container .select-card {
  color: #24262b;
  opacity: 0.6;
}
.select-card-container .new-card {
  font: normal normal 600 16px/19px Gilroy;
  color: #ee5050;
  cursor: pointer;
}
.saved-card-container {
  display: flex;
  flex-direction: row;
  border: 1px solid #979797;
  border-radius: 6px;
  height: 52px;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  opacity: 1;
}
.saved-card-container .saved-card {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 18px/22px Gilroy;
  letter-spacing: 0px;
  color: #24262b;
  opacity: 0.6;
}
.card-container {
  border: 1px solid #979797;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
  overflow-y: scroll;
}
.card-container .close-btn {
  display: flex;
  justify-content: flex-end;
  color: #929395;
  margin: 3px;
  cursor: pointer;
}
.card-container .card-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.card-container .card-detail:-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ee5050;
}
.card-container .card-detail:hover {
  background-color: #fde3e3;
  padding: 3px;
  border-radius: 6px;
}
.card-container .card-number {
  font: normal normal 600 22px/27px Gilroy;
  letter-spacing: 0px;
  color: #24262b;
  opacity: 1;
}
.dlt-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}
.card-type {
  font-weight: bolder;
  color: #00217f;
  font-style: oblique;
}
.new-card-btn {
  border-radius: 6px;
  border: 0px;
  background: #24262b;
  margin: -2px;
  padding: -1px;
  color: #fff;
  height: 40px;
}
.delete-btn-container {
  height: 41px;
  width: 41px;
  background-color: #f3e5e8;
  color: #ee5050;
  text-align: center;
  height: 40px;
  border-radius: 6px;
  margin-right: 10px;
  padding-top: 2px;
}
.credit-card-container {
  display: flex;
  flex-direction: row;
  height: 250px;
  width: 475px;
  background-color: #fff;
  border-radius: 16px;
}
.credit-card-container .card-num-label {
  font-size: 14px;
  color: #515151;
}
.credit-card-container .card-num {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  font-size: 24px;
}
.credit-card-container .card-brand-container {
  display: flex;
  align-items: center;
  width: 125px;
  background-color: #ee5050;
  color: #fff;
  font-size: larger;
  font-weight: 600;
  justify-content: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
input[type='radio'] {
  -webkit-appearance: checkbox;
  /* Chrome, Safari, Opera */
  -moz-appearance: checkbox;
  /* Firefox */
  -ms-appearance: checkbox;
  /* not currently supported */
}
input[type='radio'] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  content: none;
  margin-left: 5px;
}
input[type='radio']:before {
  font-family: 'FontAwesome';
  content: '\f00c';
  font-size: 10px !important;
  color: transparent !important;
  background: #fff;
  width: 15px;
  height: 15px;
  border: 1px solid #ee5050;
  margin-right: 5px;
}
input[type='radio']:checked:before {
  color: black !important;
}
.save-radio-btn {
  cursor: pointer;
}

.coupon-card {
  color: #24262b;
  opacity: 0.6;
}

.coupon-button {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  padding: 5px;
  width: 34%;
  border-radius: 5px;
  font-weight: bold;
}
.amount-container {
  margin-top: 10px;
  background-color: #f4f6f9;
  display: flex;
  flex-direction: column;
  padding: 10px;
  font: normal normal 600 18px/22px Gilroy;
  letter-spacing: 0px;
  color: #24262b;
}

.before-amount {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  padding: 10px;
}

.amount-name {
  opacity: '0.4';
  width: 80%;
}

.new-coupon-button {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  padding: 5px;
  width: 34%;
  border-radius: 5px;
  font-weight: bold;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 768px) {
  .new-payment-amount {
    font: normal normal bold 25px/30px Gilroy;
  }
  .new-coupon-button {
    padding: 0px;
    width: 50%;
    margin-right: -2%;
  }
  .dollor-width-margin {
    width: 30%;
    margin-left: 30px;
  }
}
