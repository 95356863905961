.searchbar {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(209, 209, 209);
  padding: 12px 0px 16px 3.47%;
}

.btnParent {
  /* border: 2px solid green; */
  display: flex;
  justify-content: flex-end;
  padding-right: 1%;
}

.filterButton {
  /* border: 2px solid blue; */
  background-color: #ee5050;
  border-radius: 6px;
  padding: 10px 25px;
  height: unset;
  color: white;
  font: normal normal 400 16px/18px Gilroy semiBold;
}
.searchCol {
  display: flex;
  justify-content: space-between;
}
.locationInput {
  /* justify-content: space-between !important; */
  margin-left: 5%;
}

.searchCategory {
  margin-left: 5%;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .btnParent {
    /* border: 2px solid green; */
    display: flex;
    justify-content: center;
    padding-right: 0px;
  }
  .locationInput {
    /* justify-content: space-between !important; */
    margin-left: 0%;
  }

  .searchCategory {
    margin-left: 0%;
  }

  .filterButton {
    padding-right: 0%;
    display: flex;
    justify-content: center;
    width: 100% !important;
    background-color: #ee5050;
    border-radius: 6px;
    padding: 10px 25px;
    height: unset;
    color: white;
    font: normal normal 400 16px/18px Gilroy semiBold;
    margin: auto;
  }
  .searchbar {
    background: transparent;
    width: 90%;
    margin: auto;
    margin-bottom: -120px;
    padding-left: 0px !important;
  }
  .btnParent {
    width: 100%;
  }
}
