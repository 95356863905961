.image {
  width: 90%;
  height: 120px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .image {
    width: 80%;
    height: 90px;
    margin-left: 10%;
  }
}
