.back-icon {
  vertical-align: text-bottom;
  color: #afb5c0;
  cursor: pointer;
}
.back-btn {
  cursor: pointer;
  color: #afb5c0;
  margin-left: 10px;
}
.add-service-form {
  margin-top: 30px;
}
.add-service-form label {
  color: #24262b;
  opacity: 0.6;
  font: normal normal 400 16px/32px Gilroy SemiBold;
  width: 100%;
}
.s-input-fields {
  font: normal normal 400 18px/32px Gilroy SemiBold;
  color: #24262b;
  width: 100%;
}
.s-input-fields,
.s-input-fields .ant-input,
.s-input-fields .ant-select-selector {
  background-color: #ffffff;
  border: 0 !important;
  border-radius: 5px;
  height: 50px !important;
}
.s-input-fields .ant-select-selector,
.s-input-fields .ant-select-selector .ant-select-selection-item {
  padding: 10px;
  height: 50px !important;
}
.ant-select-multiple .ant-select-selector .ant-select-selection-item {
  padding: 5px 8px 5px 8px !important;
  height: 30px !important;
  background: #ee505010 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #ee5050;
  /*font: normal normal 400 12px/32px Gilroy SemiBold;*/
  font: normal normal 400 15px/18px Gilroy Bold !important;
}
.s-input-fields .ant-input-group-addon {
  height: 37.97px;
  background-color: red;
  border: 0 !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #ffffff !important;
}
.s-input-fields .ant-input-group-addon .ant-select-selection-item {
  padding: 11px;
  padding-right: 18px;
}
.s-input-fields .ant-input-group-addon .ant-select,
.s-input-fields .ant-input-group-addon .ant-select-arrow {
  font-weight: bold;
  color: #ffffff !important;
}
.s-input-fields .ant-upload,
.s-input-fields .ant-upload button {
  width: 70%;
  background-color: #ffffff;
  border-radius: 5px;
  height: 50px !important;
}
.s-input-fields .ant-upload button {
  border: 1px dashed #979797;
  width: 100%;
}
.s-file-upload {
  position: absolute;
  right: 0;
  top: 5px;
  border-radius: 25px;
  height: 40px !important;
  border-color: #ee5050;
  color: #ee5050;
  opacity: 1;
  font-weight: bold;
}
.short-desc-input {
  background-color: #ffffff !important;
}

.currency-select-style .ant-select-selector {
  background-color: #ee5050 !important;
  border-radius: 6px 0 0 6px !important;
  border: 0 !important;
  height: 50px !important;
  font: normal normal 400 18px/32px Gilroy SemiBold;
  color: #24262b !important;
  padding: 12px 15px !important;
}
.currency-select-style .ant-select-selector,
.currency-select .ant-select-selector .ant-select-selection-item {
  /* padding: 0px 20px; */
  height: 50px !important;
  color: #ffffff !important;
}

/* .image-container {
    position: relative;
    margin-top: 50px;
    width: 300px;
    height: 300px;
  }
  .image {
    position: absolute;
    width: 300px;
    height: 400px;
    left: 0;
  }
  .delete-service-btn-info-media {
    position: absolute;
    width: 300px;
    left: 80px;
    top: 3px;
    font-size: 1.2rem;
    text-align: center;
    opacity: 0;
    transition: opacity 0.35s ease;
    z-index: 1;
    color: red;
  }
  .image-container:hover .delete-btn-info-media {
    opacity: 1;
  } */
.price-input-service {
  font: normal normal 400 18px/32px Gilroy SemiBold;
  color: #24262b;
  border: 0px !important;
  height: 50px !important;
  width: 400px !important;
  max-width: 90%;
}
@media screen and (max-width: 1440px) {
  .price-input-service {
    font: normal normal 400 18px/32px Gilroy SemiBold;
    color: #24262b;
    width: 100%;
    border: 0px !important;
    height: 50px !important;
    width: 335px !important;
  }
}
.service-img {
  border: 2px solid #ee5050;
}
.product-img-edit {
  object-fit: contain !important;
}
.ant-image-img {
  object-fit: contain;
}
