.change-password-btn {
  width: 200px;
  background-color: #ffffff99;
  cursor: pointer;
  font: normal normal 400 16px/18px Gilroy Bold;
  color: #ee5050;
  height: 37px;
  justify-content: center;
  border-radius: 19px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}
.change-password-btn-container2 {
  display: flex;
  /* justify-content: space-around; */
  float: right;
  align-items: center;
}


.profile_image1 {
  width: 119px;
  height: 119px;
  max-width: 100%;
  border-radius: 50%;
}



.add-user-upload-image {
    border: 1px solid #E4E8F0;
    border-radius: 13px;
    padding: 5px 40px;
    margin: 2px;
    background: #fff;
    display: inline-block;
    text-align: center;
}