/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .goBackIcons {
    margin-left: 20px !important;
  }
  .picPlan {
    font-size: 30px !important;
    margin-bottom: -30px;
  }
}
