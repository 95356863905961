.bannerImage {
  width: 100%;
  position: relative;
  height: 400px;
}
.profileIcons {
  /* border: 5px solid rgb(8, 179, 48); */
  width: 80%;
  border-radius: 10px;
  display: block;
  object-fit: cover;
  height: 120px;
}
.socialMedia {
  /* border: 2px solid black; */
  width: 97%;
  margin: auto;
}
.socialMedia a {
  text-decoration: none;
  color: #c82727;
  font-size: 35px;
}
.educationImage {
  padding-left: 29px;
  padding-bottom: 25px;
}
.educationHeading {
  font: normal normal 400 25px/30px Gilroy semiBold !important;
}
.educationSubHeading {
  font: normal normal 400 20px/25px Gilroy semiBold !important;
}
.personalInfoParentCOntainer {
  background: white;
  padding-bottom: 20;
  /* border: 2px solid red; */
}
.currentPositionCards {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardsImage {
  padding-left: 3%;
}
.currentPositionCardsImage {
  border-radius: 10px;
  width: 80%;
  height: 120px;
}

.profileSectionHeading {
  font: normal normal 400 30px/33px Gilroy semiBold !important;
  margin-bottom: -5px;
}
.profileSectionSubHeading {
  font: normal normal 400 22px/28px Gilroy semiBold !important;
  margin-bottom: -5px;
}
.resumeSection {
  margin-top: 30px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .bannerDiv {
    overflow: hidden;
    margin-top: -80px;
    margin-top: 0px;
  }
  .bannerImage {
    object-fit: inherit !important;
    height: 220px;
    /* border: 2px solid red; */
    margin-top: -70px;
  }
  .profileIcons {
    width: 100%;
    height: 90px;
    border-radius: 50%;
    margin-left: 5px;
  }

  .skillsData {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .desiredLocationData {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .educationImage {
    padding-left: 0;
    padding-bottom: 0;
  }
  .educationHeading {
    font: normal normal 400 20px/25px Gilroy semiBold !important;
  }
  .educationSubHeading {
    font: normal normal 400 15px/17px Gilroy semiBold !important;
  }
  .educationRow2 {
    padding-left: 15px;
  }
  .currentPositionCards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid black; */
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .personalInfoParentCOntainer {
    background: white;
    padding-bottom: 20;
  }
  .cardsImage {
    padding-left: 3%;
  }
  .currentPositionCardsImage {
    border-radius: 10px;
    width: 90%;
    height: 100px;
    object-fit: cover;
  }
  .visitProfileHeadings {
    font: normal normal 400 20px/25px Gilroy semiBold !important;
  }
  .visitProfileSubHeadings {
    font: normal normal 400 15px Gilroy semiBold !important;
  }
  .closableDivs {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    /* border: 2px solid red; */
    padding-bottom: 10px;
    background: whitesmoke;
  }
  .closableDivsTexts {
    font: normal normal 400 14px Gilroy semiBold !important;
  }
  .closableSubDivs {
    /* border: 2px solid rgb(21, 0, 255); */
    /* width: 100% !important; */
  }
  .closableSubDivsParents {
    /* border-bottom: 0px solid rgb(21, 0, 255); */
    margin-left: -30px !important;
    margin-right: -20px !important;
    /* width: 100% !important; */
  }

  .socialMedia a {
    text-decoration: none;
    color: #c82727;
    font-size: 25px;
  }
  .visitInfoCard6 {
    padding-left: 0px;
    height: auto !important;
    padding-bottom: 5px;
    margin-top: -10px;
    margin-bottom: 0px;
  }
  .visitInfoCard6 p {
    font-size: 13px !important;
  }
  .visitInfoCard7 {
    margin-left: 10px;
    padding-right: 5;
    border-right: 1px solid #e2e4e9;
    width: 33%;
  }
  .visitInfoCard8 {
    padding-right: 10px;
    margin-left: -20px;
    width: 50%;
  }
  .visitInfoCard612 {
    margin-right: -10px;
    margin-left: 10px;
  }
  .availabilityDiv {
    margin-top: -15px;
  }
  .emailFontIcons {
    font-size: 13px !important;
    width: 100%;
    margin-left: 0px !important;
  }
  .locationBoxTetx {
    font-size: 13px !important;
    margin-left: -1px !important;
  }
  .phoneNumberSection {
    margin-bottom: -18px;
    margin-top: -18px;
  }
  .phoneNumberSection > p {
    display: flex;
    gap: 10px;
    font-size: 13px !important;
    padding-left: 0px;
  }
  .profileSectionHeading {
    /* border: 2px solid red; */
    font: normal normal 200 20px/23px Gilroy semiBold !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 2px;
  }
  .profileSectionSubHeading {
    /* border: 2px solid rgb(0, 13, 255); */
    font: normal normal 200 16px/18px Gilroy semiBold !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-bottom: 2px;
  }
  .resumeSection {
    border: 2px solid red;
    display: none;
  }

  .visitInfoCardcol2 {
    padding-left: 10px;
  }
  .locationPng {
    height: 15px !important;
    margin-top: 3px;
  }
  .mobileIcons {
    margin-left: -5px;
  }
  .socialMediaPadding {
    margin-bottom: -30px;
  }
}
