.bannerImage {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.buttonContainer {
  width: 50%;
}
.applybtnBigScreen {
  width: 100%;
  margin-right: 10px;
}
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .mainContainer {
    padding: 0px !important;
  }
  .childContainerOne {
    margin: auto;
    margin-top: -50px !important;
    margin-bottom: 40px !important;
  }
  .childContainerTwo {
    margin: auto;
    margin-top: 0px !important;
  }
  .bannerImage {
    height: 200px;
  }
  .buttonContainer {
    width: 100%;
  }
  .applybtnBigScreen {
    margin-right: 0px;
  }
  .btnW100 {
    width: 100% !important;
  }
  .locationAndNumber {
    display: flex !important;
    flex-direction: column !important;
    gap: 15px !important;
  }
  .paddingTopZero {
    padding-top: 0px !important;
  }
}
