.jobListingcls-1 {
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  opacity: 0.7;
}
.jobListingclscard-1:active {
  border: 3px solid #5981df;
  border-radius: 10px;
  opacity: 1;
}
.paginate-container {
  display: flex;
  justify-content: center;
  padding: 5px;
}
.date-range-filter {
  height: 40px;
  border-radius: 6px;
}
.header-input-fields .ant-select-selector .ant-select-selection-search input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0 !important;
  border-radius: 6px !important;
  opacity: 1 !important;
  font: normal normal 400 16px Gilroy SemiBold;
  /*height: 52px !important;*/
}
.header-input-fields
  .ant-select-selector
  .ant-select-selection-search
  input:focus {
  box-shadow: 0 0 0 1px rgb(238, 80, 80, 0) !important;
}
.header-input-fields
  .ant-select-selector
  .ant-select-selection-search
  .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 1px rgb(238, 80, 80, 0) !important;
}
.header-input-fields
  .ant-select-selector
  .ant-select-selection-search
  .ant-input-affix-wrapper {
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
  padding: 8px 10px !important;
}
.header-input-fields
  .ant-select-selector
  .ant-select-selection-search
  .ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 0px rgb(24 144 255 / 0%) !important;
}
.header-input-fields
  .ant-select-selector
  .ant-select-selection-search
  .ant-input-affix-wrapper:hover {
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
}
.landing-icons-2 {
  font: normal normal 400 17px Gilroy Bold;
  color: #b02318;
  vertical-align: super;
}
.custom-container {
  overflow: auto;
  height: 66vh;
}
.right-card-border {
  border-right: 1px solid #24262b38;
}
.company-card-6 {
  font: normal normal 400 16px/19px Gilroy semiBold;
  letter-spacing: -0.22px;
  color: #24262b;
}
.company-card-7 {
  letter-spacing: -0.19px;
  color: #24262b;
  opacity: 0.4;
  font: normal normal 400 14px/17px Gilroy semiBold;
}
.company-card-8 {
  padding: 0 0 0 15px;
}
.active-page {
  color: #fff;
  border-radius: 50%;
  background: black;
}

a {
  padding: 10px;
}
.job-card-1 {
  padding-right: 5%;
  padding-left: 5%;
}

.job-card-2 {
  background-color: white;
  border-radius: 6px;
  height: 100%;
  cursor: pointer;
}
.job-card-2:hover {
  box-shadow: 0px 2px 24px #0000002a;
}
.job-card-3 {
  font: normal normal 400 26px/31px Gilroy semiBold;
  letter-spacing: -0.36px;
  color: #24262b;
  margin: 0;
}
.job-card-4 {
  font: normal normal 400 16px/18px Gilroy semiBold;
  letter-spacing: -0.21px;
  color: #24262b;
  /* margin: 5px; */
  text-transform: uppercase;
}
.job-card-5 {
  font: normal normal 400 16px/18px Gilroy semiBold;
  letter-spacing: -0.22px;
  color: #00000080;
  margin: 0;
  padding: 5px 0px;
}
.job-card-6 {
  list-style: none;
  display: inline-flex;
  font: normal normal 400 16px/18px Gilroy semiBold;
  letter-spacing: -0.22px;
  color: #00000080;
  margin: 0;
  padding: 0;
  width: 100%;
}

.job-card-6 li {
  padding-right: 35px;
}

.job-card-6 > li > p {
  margin: 0;
}
.job-card-7 {
  font: normal normal 400 14px/16px Gilroy semiBold;
  background: #ee505030;
  border-radius: 2px;
  color: #ee5050;
  text-align: center;
  padding: 2px 0px;
}
.job-card-8 {
  font: normal normal 400 16px/18px Gilroy semiBold;
  color: #5981df;
  font-size: 0.9rem;
}

.remove-search-line > .ant-input-affix-wrapper > .ant-input:focus {
  box-shadow: 0 0 0 0px rgb(238, 80, 80, 0.2) !important;
}
.page-item.active .page-link {
  color: #ffffff !important;
  background-color: #24262b !important;
  border-radius: 50% !important;
}
.page-link {
  background-color: #f4f6f9 !important;
  border-radius: 50% !important;
  color: #24262b !important;
  font-weight: 600 !important;
  border: 0px !important;
}

.filter-button button {
  background-color: #ee5050;
  border-radius: 6px;
  padding: 10px 25px;
  height: unset;
  color: white;
  font: normal normal 400 16px/18px Gilroy semiBold;
}

.filter-button > .ant-btn-text:active,
.ant-btn-text:focus,
.ant-btn-text:hover {
  color: white;
  background-color: #ee5050;
  border-color: transparent;
}

.event-name {
  text-transform: capitalize;
  cursor: pointer;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: #b02318;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ee505010;
}
.comp-hover {
  padding: 0;
}
.companyText {
  font: normal normal 200 20px/25px Gilroy semiBold;
  color: rgb(28, 28, 28) !important;
}
.companyText:hover {
  color: #e45150 !important;
  text-decoration: underline;
}
.comp-hover:hover {
  color: #e45150;
  text-decoration: underline;
}
.ant-picker.s-search-select.padding-in-job-datepicker
  > .ant-picker-input
  > input:placeholder-shown {
  font: normal normal 400 16px Gilroy SemiBold;
}

.ant-form input[id='pac_input'] {
  box-sizing: border-box;
  height: 2.5rem;
  opacity: 1 !important;
  font: normal normal 400 16px Gilroy SemiBold;
}

span.anticon.anticon-environment.landing-search-bar-location-icon.landing-select-icon
  + input[id='pac_input'] {
  height: 57px;
}

.ant-picker-input > input {
  font-family: 'Gilroy SemiBold';
  font-size: 16px;
  text-overflow: ellipsis;
}

input#pac_input::placeholder {
  color: rgba(36, 38, 43, 0.35);
}
.mapIcon svg {
  font-size: 18px;
  color: #ee5050;
  rotate: -45deg;
}

.job-card-location-section {
  font: normal normal 400 16px/18px Gilroy semiBold;
  letter-spacing: -0.22px;
  color: #00000080;
  margin-bottom: 20px;
}

.company-card-bottom-section-number-of-emp {
  font: normal normal 400 16px/18px Gilroy semiBold;
  letter-spacing: -0.22px;
  color: #00000080;
}

/*=======================for mobile screen size=======================*/
@media screen and (max-width: 600px) {
  .custom-container {
    overflow: auto;
    height: 100%;
  }
}
