.chatBoxDisplayNone {
  display: none;
}
/* .tst {
  z-index: 100;
} */
/*=======================for mobile screen size=======================*/
@media screen and (max-width: 768px) {
  .mailBoxContainer {
    width: 90%;
    margin: auto;
    /* border: 2px solid red; */
  }
  .mailBoxHeading {
    margin-top: 20px;
  }
  .mailBoxSearchbox {
    margin-bottom: -35px;
  }
  .chatBoxDisplayNone {
    display: block;
  }
  .chatBoxMainConatiner {
    display: none;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
  }
  .mainContainer {
    margin-top: 20px;
  }
  .closeIcons {
    font: normal normal 900 16px/18px Gilroy semiBold;
    font-weight: bolder;
    font-size: 25px;
    margin-top: 10px;
    letter-spacing: -0.22px;
    color: #00000098;
  }
  .displayNoneForSmall {
    display: none;
  }
}
