.upperRow {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.secondRow {
  margin-top: 10px;
}
.rightColumn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.resource {
  font-family: 'Gilroy Bold';
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
}

.createButton {
  background-color: #343a40;
  border-color: #343a40;
  color: #fff;
  width: 230px;
  height: 50px;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Gilroy Bold';
  border-radius: 0.25rem;
}
.createButton:hover {
  background-color: #23272b;
  border-color: #1d2124;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Gilroy Bold';
}

.countButton {
  background-color: rgb(238, 80, 80);
  width: 100%;
  height: 40px;
  border-radius: 6px;
  color: white;
  text-align: center;
  padding: 10px 25px;
  font-family: 'Gilroy Bold';
  font-size: 14px;
  margin-left: 10px;
}

.sortField .ant-select-selector {
  height: 40px !important;
  width: 100%;
  text-align: center;
}
.searchFields {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search{
  height: 40px;
  width: 100%;
}
.buttonRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.goBack {
  font-size: 16px;
  cursor: pointer;
  color: #afb5c0;
  margin-left: 10px;
}
.goBackDiv {
  display: flex;
  align-items: center;
}
.formFields {
  margin-top: 50px;
}
.inputLabel {
  color: #24262b7a;
  font: normal normal 400 16px/19px Gilroy semiBold;
  margin-bottom: 15px;
  margin-top: 15px;
}
.inputTab {
  text-overflow: ellipsis;
  background-color: #fff;
  border: 0 !important;
  border-radius: 5px;
  height: 50px !important;
  font: normal normal 400 18px/32px Gilroy SemiBold;
  width: 100%;
}
.inputTabInfo {
  text-overflow: ellipsis;
  background-color: #fff;
  border: 0 !important;
  border-radius: 5px;
  height: 100px !important;
  font: normal normal 400 18px/32px Gilroy SemiBold;
  width: 100%;
}
.resourceFields {
  color: #ff342b;
}
.mainDiv {
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
}
.innerMainDiv {
  padding: 23px 25px 18px 30px;
}
.image {
  /* margin: 7px 0 0 2px; */
  width: 90%;
  height: 100px;
  max-height: 90px;
  object-fit: cover;
  border-radius: 10px;
}
.headingName {
  font-weight: 600;
}
.titleNames {
  color: #ed5050;
  font-weight: 700;
  font-size: 14px;
}
.titleData {
  margin-left: 5px;
  font: normal normal 400 19px Gilroy semiBold;
  font-size: 14px;
}
.innerRows {
  margin-top: 1px;
}
.innerRows1 {
  margin-top: 1px;
}
.searchBar {
  padding: 12px 7.64% 16px 3.47%;
  background: #ffffff;
  border-top: 1px solid #d1d1d1;
  display: flex;
  justify-content: space-between;
}
.filterButton {
  background-color: #ee5050;
  border-radius: 6px;
  /* padding: 10px 25px; */
  text-align: center;
  color: white;
  height: 40px;
  font: normal normal 400 16px/18px Gilroy semiBold;
}
.filterButtonCol {
  display: flex;
  justify-content: flex-end;
}

.searchCardRow {
  display: flex;
  justify-content: center;
  margin: 0.5rem !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  position: relative;
  border-radius: 15px;
  padding: 15px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.searchTitle {
  text-transform: capitalize;
  font: normal normal 400 24px/31px Gilroy semiBold;
  letter-spacing: -0.36px;
  color: #24262b;
  margin: 0;
}
.searchCompanyName {
  font: normal normal 400 16px/18px Gilroy semiBold;
  letter-spacing: -0.21px;
  color: #24262b;
  margin: 5px;
  text-transform: uppercase;
}
.searchCompanyName:hover {
  color: #ee5050;
  text-decoration: underline;
  cursor: pointer;
}
.arrow {
  color: #ee5050;
  rotate: -45deg;
  margin-bottom: 65px;
  cursor: pointer;
}
.arrowCol {
  display: flex;
  /* align-items: ; */
  justify-content: flex-end;
  font-size: 18px;
}
.locationImg {
  font-size: 0.99rem;
  justify-content: space-between;
}
.resourceText {
  font: normal normal 400 16px/18px Gilroy semiBold;
  letter-spacing: -0.22px;
  color: #00000080;
  opacity: 0.3rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.marginLeft{
  margin-top: 10px;
  margin-left: 25px;
  color: #ee5050;
  font: normal normal 400 16px/18px Gilroy semiBold;
}
.marginLeft1{
  margin-left: 25px;
  font: normal normal 400 16px/18px Gilroy semiBold;
}
.fieldsValue{
  color: #ee5050;
  font: normal normal 400 16px/18px Gilroy semiBold;
}
.editFields {
  color: #ee5050;
  font-size: 20px;
  padding: 0 15px 0 0;
  vertical-align: top;
  cursor: pointer;
}
.editFields:hover {
  color: #ee5050;
  font-size: 20px;
  padding: 0 15px 0 0;
  vertical-align: top;
  cursor: pointer;
}
.editCol {
  display: flex;
  justify-content: flex-end;
}

.collapseHeaders {
  color: #ed5050;
  font-weight: bold;
  padding: 5px;
  font: normal normal 400 16px/18px Gilroy semiBold;
}
.rowCollapse {
  border: none;
  background-color: #f4f6f9;
  border-radius: 0 0 10px 10px;
}
.collapseValue {
  padding: 5px;
  font: normal normal 400 16px/18px Gilroy semiBold;
  letter-spacing: -0.21px;
  color: #24262b;
}
.collapse {
  display: flex;
  justify-content: center;
  padding: 15px;
}
.map {
  height: 600px;
}
.resourceCards {
  overflow: auto !important;
  height: 600px;
}
.collapseCol {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.collapeseContact {
  color: black;
  font-weight: bold;
  padding: 15px 0px 15px 5px;
  font: normal normal 800 16px/18px Gilroy Bold;
}
.collapseValueCompany {
  padding: 5px;
  font: normal normal 400 16px/18px Gilroy semiBold;
  letter-spacing: -0.21px;
  text-transform: uppercase;
  color: #24262b !important;
}
.skillAdd {
  padding: 5px;
  border-radius: 5px;
}
.mainDiv {
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  width: 100%;
}
.companyText {
  font: normal normal 200 15px/15px Gilroy semiBold;
  color: rgb(28, 28, 28) !important;
  padding: 0px !important;
}
.emailIcon{
  margin-left: -8px;
}
/*================ for mobile screens ====================*/
@media screen and (max-width: 768px) {
  .filterButton {
    width: 100%;
  }
  .addResource{
    margin-top: 20px;
    margin-left: 20px;
  }
  .buttonCol{
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .createButton {
    background-color: #343a40;
    border-color: #343a40;
    color: #fff;
    width: 90%;
    height: 50px;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Gilroy Bold';
    border-radius: 0.25rem;
  }
  .formFields {
    margin-top: 50px;
    margin: 50px 20px 0px 20px;
  }
  .collapse1{
    width: 100%;
  }
  .appliedBg{
    background-color: #ffedee;
    padding: 3px;
    border-radius: 20px;
  }
  .editFields{
    display: none;
  }
  .mainDiv {
    margin-top: 20px;
    background-color: white;
    border-radius: 8px;
    width: 90%;
  }
  .rightColumn{
    display: none;
  }
  .mainBodyContainer {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin: auto;
  }
  .searchBar {
    padding: 20px 5% 16px 5%;
  }
  .locationBox {
    width: 100% !important;
  }
  .map {
    margin-top: 10px;
    margin-bottom: 300px;
  }
  .numberOfCard {
    font: normal normal 400 18px Gilroy SemiBold !important;
    padding-left: 10px !important;
  }
  .searchCardRow {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    padding: 15px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
  }
  .image {
    margin-right: 0px;
    width: 100%;
  }
  .searchTitle {
    font: normal normal 400 16px/20px Gilroy semiBold;
  }
  .collapseHeaders {
    padding: 3px;
    font: normal normal 200 13px/15px Gilroy semiBold;
  }
  .emailIcon{
    margin-left: -10px;
  }
  .collapseValue {
    padding: 3px;
    font: normal normal 200 13px/15px Gilroy semiBold;
    letter-spacing: -0.21px;
  }
  .collapseValueCompany {
    padding: 3px;
    font: normal normal 200 13px/15px Gilroy semiBold;
  }
  .collapeseContact {
    padding: 10px;
    font: normal normal 400 14px/16px Gilroy semiBold;
  }
  .titleNames {
    font-weight: 600;
    font-size: 13px;
  }
  .titleData {
    font: normal normal 400 13px Gilroy semiBold;
    font-size: 13px;
  }
  .arrow {
    margin-bottom: 135px;
    cursor: pointer;
  }
  .search{
    height: 50px;
    width: 90%;
  }
}
